import { ButtonProps } from '@mantine/core'
import { modals } from '@mantine/modals'

export function confirmModal(params: {
  title: string
  content: React.ReactNode
  labels: { confirm: string; cancel: string }
  onCancel?: () => void
  onConfirm?: () => void
  confirmProps?: ButtonProps & React.ComponentPropsWithoutRef<'button'>
}) {
  const modalId = crypto.randomUUID()
  modals.openConfirmModal({
    centered: true,
    modalId: modalId,
    title: params.title,
    children: params.content,
    labels: params.labels,
    confirmProps: params.confirmProps || { color: 'red' },
    groupProps: { align: 'space-between' },
    onCancel: params.onCancel,
    onConfirm: params.onConfirm,
  })
}
