import { Admin } from '@scribe/types/src/admin'
import ApiService from 'services/ApiService'
import qs from 'qs'

export default class WorkspacesTransportLayer {
  public async getWorkspaces(
    params: Admin.WorkspacesIndex.RequestQuery,
  ): Promise<Admin.WorkspacesIndex.ResponseBody | null> {
    const response = await ApiService.get<Admin.WorkspacesIndex.ResponseBody>(
      `/workspaces?${qs.stringify(params)}`,
    )
    if (response.outcome === 'success') {
      return response.data
    }
    return null
  }

  public async updateWorkspace(
    workspaceId: number,
    params: Admin.WorkspacesUpdate.RequestBody['workspace'],
  ) {
    const response = await ApiService.put<
      Admin.WorkspacesUpdate.RequestBody,
      Admin.WorkspacesUpdate.ResponseBody,
      void
    >(`/workspaces/${workspaceId}`, { workspace: params })
    return response
  }

  public async deleteWorkspace(workspaceId: number) {
    const response =
      await ApiService.delete<Admin.WorkspacesDelete.ResponseBody>(
        `/workspaces/${workspaceId}`,
      )
    return response
  }
}
