import { Admin, EnterpriseCheckout } from '@scribe/types/src/admin'
import autoBind from 'auto-bind'
import dayjs from 'dayjs'
import BaseModel from 'engine/BaseModel'
import EnterpriseCheckoutsTransportLayer from 'engine/enterpriseCheckouts/EnterpriseCheckoutsTransportLayer'
import { mergeValuesToModel } from 'engine/mobxUtils'
import { makeAutoObservable, observable } from 'mobx'
import { createViewModel, IViewModel } from 'mobx-utils'

function defaultAttributes(): EnterpriseCheckout {
  return {
    id: null,
    uuid: '',
    first_name: '',
    last_name: '',
    job_position: '',
    email: '',
    seat_count: 100,
    plan: 'enterprise',
    billing_interval: 'yearly',
    stripe_coupon_id: '',
    activated_at: '',
    contract_sent_at: '',
    contract_signed_at: '',
    company_name: '',
    street_name: '',
    street_number: '',
    box_number: '',
    zip_code: '',
    city: '',
    country_code: '',
    emails: [],
    currency_code: 'USD',
    created_at: '',
    updated_at: '',
  }
}

export default class EnterpriseCheckoutModel implements BaseModel {
  private _enterpriseCheckout: EnterpriseCheckout &
    IViewModel<EnterpriseCheckout>
  private _enterpriseCheckoutsTransportLayer: EnterpriseCheckoutsTransportLayer
  private _persisted = false
  private _isSaving = false

  constructor(
    enterpriseCheckoutsTransportLayer: EnterpriseCheckoutsTransportLayer,
    attributes?: Partial<EnterpriseCheckout>,
  ) {
    makeAutoObservable(this)
    autoBind(this)
    this._enterpriseCheckout = createViewModel(
      observable({ ...defaultAttributes(), ...attributes }),
    )
    if (!this._enterpriseCheckout.uuid) {
      this._enterpriseCheckout.uuid = crypto.randomUUID()
    }
    this._persisted = !!this.createdAt
    this._enterpriseCheckoutsTransportLayer = enterpriseCheckoutsTransportLayer
  }

  public get persisted() {
    return this._persisted
  }

  public get isSaving() {
    return this._isSaving
  }

  public get firstName() {
    return this._enterpriseCheckout.first_name
  }

  public get lastName() {
    return this._enterpriseCheckout.last_name
  }

  public get jobPosition() {
    return this._enterpriseCheckout.job_position
  }

  public get email() {
    return this._enterpriseCheckout.email
  }

  public get seatCount() {
    return this._enterpriseCheckout.seat_count
  }

  public get plan() {
    return this._enterpriseCheckout.plan || 'enterprise'
  }

  public get billingInterval() {
    return this._enterpriseCheckout.billing_interval
  }

  public get stripeCouponId() {
    return this._enterpriseCheckout.stripe_coupon_id
  }

  public get companyName() {
    return this._enterpriseCheckout.company_name
  }

  public get streetName() {
    return this._enterpriseCheckout.street_name
  }

  public get currencyCode() {
    return this._enterpriseCheckout.currency_code
  }

  public get streetNumber() {
    return this._enterpriseCheckout.street_number
  }

  public get boxNumber() {
    return this._enterpriseCheckout.box_number
  }

  public get city() {
    return this._enterpriseCheckout.city
  }

  public get zipCode() {
    return this._enterpriseCheckout.zip_code
  }

  public get countryCode() {
    return this._enterpriseCheckout.country_code
  }

  public get emails() {
    return this._enterpriseCheckout.emails
  }

  public get activatedAt() {
    if (!this._enterpriseCheckout.activated_at) {
      return null
    }
    return dayjs(this._enterpriseCheckout.activated_at)
  }

  public get contractSentAt() {
    if (!this._enterpriseCheckout.contract_sent_at) {
      return null
    }
    return dayjs(this._enterpriseCheckout.contract_sent_at)
  }

  public get contractSignedAt() {
    if (!this._enterpriseCheckout.contract_signed_at) {
      return null
    }
    return dayjs(this._enterpriseCheckout.contract_signed_at)
  }

  public get id() {
    if (!this._enterpriseCheckout.id) {
      throw new Error('EnterpriseCheckout id is null')
    }
    return this._enterpriseCheckout.id
  }

  public get uuid() {
    if (this._enterpriseCheckout.uuid === '') {
      throw new Error('EnterpriseCheckout uuid is empty')
    }
    return this._enterpriseCheckout.uuid
  }

  public get createdAt() {
    if (!this._enterpriseCheckout.created_at) {
      return null
    }
    return dayjs(this._enterpriseCheckout.created_at)
  }

  public get updatedAt() {
    if (!this._enterpriseCheckout.updated_at) {
      return null
    }
    return dayjs(this._enterpriseCheckout.updated_at)
  }

  public async save(
    attributes: Admin.EnterpriseCheckoutsCreate.RequestBody['enterprise_checkout'],
  ) {
    if (this._isSaving) {
      return
    }
    this._isSaving = true
    const response = await (this._persisted
      ? this._enterpriseCheckoutsTransportLayer.update(this.id, {
          ...attributes,
          uuid: this.uuid,
        })
      : this._enterpriseCheckoutsTransportLayer.create({
          ...attributes,
          uuid: this.uuid,
        }))
    if (response.outcome === 'success') {
      this._persisted = true
      this._enterpriseCheckout.submit()
    }
    this._isSaving = false
    return response
  }

  public async sendContract(
    attributes: Admin.EnterpriseCheckoutsSendingCreate.RequestBody['enterprise_checkout'],
  ) {
    const response = await this._enterpriseCheckoutsTransportLayer.sendContract(
      this.id,
      {
        ...attributes,
      },
    )
    if (response.outcome === 'success') {
      this.setAttributes(response.data.enterprise_checkout)
    }
    return response
  }

  public setAttributes(enterpriseCheckout: EnterpriseCheckout) {
    if (
      !this.updatedAt ||
      this.updatedAt.isSameOrBefore(dayjs(enterpriseCheckout.updated_at))
    ) {
      mergeValuesToModel(this._enterpriseCheckout.model, enterpriseCheckout)
    }
  }
}
