import { modals } from '@mantine/modals'
import { Text } from '@mantine/core'
import { Pagination, Workspace } from '@scribe/types/src/admin'
import autoBind from 'auto-bind'
import { ROUTES } from 'components/application/ApplicationRoutes'
import engine from 'engine'
import WorkspaceModel from 'engine/workspaces/WorkspaceModel'
import { debounce } from 'lodash'
import { makeAutoObservable, reaction } from 'mobx'
import NavigationService from 'services/NavigationService'
import { notifications } from '@mantine/notifications'
import NotificationService from 'services/NotificationService'
import { confirmModal } from 'utils/confirmation'

const perPage = 12

export type SubscriptionKind =
  | Workspace['subscriptions'][number]['kind']
  | 'all'

export default class WorkspacesListViewModel {
  private _workspaces: WorkspaceModel[] = []
  private _pagination: Pagination | null = null
  private _searchQuery: string = ''
  private _debouncedSearchQuery: string = ''
  private _loading: boolean = false
  private _subscriptionKind: SubscriptionKind = 'all'

  constructor() {
    makeAutoObservable(this)
    autoBind(this)

    reaction(
      () => [this._debouncedSearchQuery, this._subscriptionKind],
      () => {
        this.loadPage(1)
      },
    )
  }

  public setSubscriptionKind(kind: SubscriptionKind) {
    this._subscriptionKind = kind
    this.loadPage(1)
  }

  public get subscriptionKind() {
    return this._subscriptionKind
  }

  public async loadPage(page: number) {
    this._loading = true
    const response = await engine.workspacesEngine.getWorkspaces({
      page: page,
      per_page: perPage,
      search_term: this._debouncedSearchQuery,
      subscription_kind:
        this._subscriptionKind === 'all' ? undefined : this._subscriptionKind,
    })
    if (response) {
      this._workspaces = response.workspaces
      this._pagination = response.pagination
    }
    this._loading = false
  }

  public get loading() {
    return this._loading
  }

  public setSearchQuery(query: string) {
    this._searchQuery = query
    this.debouncedSetSearchQuery()
  }

  private debouncedSetSearchQuery = debounce(() => {
    this._debouncedSearchQuery = this._searchQuery
  }, 500)

  public get searchQuery() {
    return this._searchQuery
  }

  public get workspaces() {
    return this._workspaces.filter((workspace) => !workspace.deleted)
  }

  public get pagination() {
    return this._pagination
  }

  public deleteWorkspace(workspace: WorkspaceModel) {
    confirmModal({
      title: 'Please confirm your action',
      content: (
        <Text size="sm">
          Are you sure you want to delete the workspace{' '}
          <Text component="span" fw="bold">
            {workspace.name}
          </Text>
          ? This action is irreversible.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        this._loading = true
        const response =
          await engine.workspacesEngine.deleteWorkspace(workspace)
        if (response && response.outcome === 'success') {
          NotificationService.success('The workspace has been deleted')
        }
        this._loading = false
      },
    })
  }

  public takeoverWorkspace(workspace: WorkspaceModel) {
    confirmModal({
      title: 'Please confirm your action',
      content: (
        <Text size="sm">
          Are you sure you want to takeover the workspace{' '}
          <Text component="span" fw="bold">
            {workspace.name}
          </Text>
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: async () => {
        window.open(
          ROUTES.AUTO_TAKEOVER.$buildPath({
            params: { userId: workspace.ownerUserId },
          }),
        )
      },
    })
  }
}
