import { Card, Group, Text } from '@mantine/core'
import SignatureTemplatesListViewModel from 'components/signatureTemplates/SignatureTemplatesListViewModel'
import SignatureTemplateModel from 'engine/signatureTemplates/SignatureTemplateModel'
import { observer } from 'mobx-react-lite'

type SignatureTemplatesListItemProps = {
  viewModel: SignatureTemplatesListViewModel
  signatureTemplate: SignatureTemplateModel
}

export default observer(function SignatureTemplatesListItem(
  props: SignatureTemplatesListItemProps,
) {
  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder h="100%">
      <Card.Section withBorder flex={1} p="lg">
        <SignatureIframe content={props.signatureTemplate.rawHtml} />
      </Card.Section>
      <Group justify="space-between" mt="md">
        <Text fw="bold">{props.signatureTemplate.workspaceName}</Text>
        <Text>{props.signatureTemplate.name}</Text>
      </Group>
    </Card>
  )
})

function SignatureIframe(props: { content: string }) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function writeHTML(frame: any) {
    if (!frame) {
      return
    }
    const doc = frame.contentDocument
    doc.open()
    doc.write(props.content)
    doc.close()
    frame.style.width = '100%'
    frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`
  }

  return (
    <iframe
      title="Signature"
      src="about:blank"
      scrolling="no"
      frameBorder="0"
      ref={writeHTML}
    />
  )
}
