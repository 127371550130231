import { ActionIcon, ComboboxItem, Group, Select } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Coupon } from '@scribe/types/src/admin'
import { IconPlus, IconRefresh } from '@tabler/icons-react'
import CouponsFormModal from 'components/coupons/CouponsFormModal'
import CouponsFormViewModel from 'components/coupons/CouponsFormViewModel'
import engine from 'engine'
import { useEffect, useMemo, useState } from 'react'

type CouponInputProps = {
  value: string
  disabled?: boolean
  onChange: (value: string | null, option: ComboboxItem) => void
  error: string | null
}

export default function CouponInput(props: CouponInputProps) {
  const [coupons, setCoupons] = useState<Coupon[]>([])
  const [loading, setLoading] = useState(false)

  const [
    couponFormModalOpened,
    { close: closeCouponFormModal, open: openCouponFormModal },
  ] = useDisclosure(false)

  const couponFormViewModel = useMemo(
    () =>
      new CouponsFormViewModel(async (coupon) => {
        closeCouponFormModal()
        loadCoupons()
        props.onChange(coupon.id, { label: coupon.name, value: coupon.id })
      }),
    [closeCouponFormModal, props],
  )
  const { disabled = false } = props

  useEffect(() => {
    loadCoupons()
  }, [])

  return (
    <>
      <Group wrap="nowrap" align="flex-end" gap="xs">
        <Select
          label="Stripe coupon"
          wrapperProps={{
            style: { flexGrow: 1 },
          }}
          clearable
          searchable
          allowDeselect={false}
          value={props.value}
          onChange={props.onChange}
          data={coupons.map((coupon) => ({
            label: coupon.name,
            value: coupon.id,
          }))}
          disabled={disabled}
        />
        <ActionIcon
          onClick={loadCoupons}
          size="input-sm"
          variant="default"
          loading={loading}
        >
          <IconRefresh
            size={16}
            style={{ width: '60%', height: '60%' }}
            stroke={1.5}
          />
        </ActionIcon>
        <ActionIcon
          onClick={openCouponFormModal}
          size="input-sm"
          variant="default"
          loading={loading}
        >
          <IconPlus
            size={16}
            style={{ width: '60%', height: '60%' }}
            stroke={1.5}
          />
        </ActionIcon>
      </Group>
      <CouponsFormModal
        formViewModel={couponFormViewModel}
        opened={couponFormModalOpened}
        onClose={closeCouponFormModal}
      />
    </>
  )

  async function loadCoupons() {
    setLoading(true)
    const response = await engine.enterpriseCheckoutsEngine.getCoupons()
    if (response && response.outcome === 'success') {
      setCoupons(response.data.coupons)
    }
    setLoading(false)
  }
}
