import { Center, Loader } from '@mantine/core'
import { ROUTES } from 'components/application/ApplicationRoutes'
import engine from 'engine'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTypedParams } from 'react-router-typesafe-routes'
import appConfig from 'utils/appConfig'

export default function AutoTakeoverScene() {
  const { userId } = useTypedParams(ROUTES.AUTO_TAKEOVER)

  useEffect(() => {
    engine.sessionEngine.userTakeover(userId)
    window.location.replace(
      `${appConfig.PROTOCOL}://${appConfig.APP_HOST}/reload`,
    )
  }, [userId])

  return (
    <>
      <Helmet>
        <title>Takeover | Scribe | Admin</title>
      </Helmet>
      <Center w={'100%'} h={500}>
        <Loader />
      </Center>
    </>
  )
}
