import {
  Box,
  Group,
  LoadingOverlay,
  Pagination,
  Select,
  Space,
  Table,
  TextInput,
} from '@mantine/core'
import { IconSearch, IconX } from '@tabler/icons-react'
import WorkspacesListItem from 'components/workspaces/WorkspacesListItem'
import WorkspacesListViewModel, {
  SubscriptionKind,
} from 'components/workspaces/WorkspacesListViewModel'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

export default observer(function WorkspacesList() {
  const viewModel = useMemo(() => new WorkspacesListViewModel(), [])

  useEffect(() => {
    viewModel.loadPage(1)
  }, [viewModel])

  return (
    <>
      <Space h="sm" />
      <Group align="flex-end">
        <TextInput
          flex={1}
          variant="filled"
          size="sm"
          placeholder="Search for a workspace ..."
          leftSection={<IconSearch size={16} />}
          onChange={(event) => viewModel.setSearchQuery(event.target.value)}
          value={viewModel.searchQuery}
          rightSection={
            viewModel.searchQuery ? (
              <IconX
                size={16}
                onClick={() => viewModel.setSearchQuery('')}
                style={{ cursor: 'pointer' }}
              />
            ) : null
          }
        />
        <Select
          wrapperProps={{
            style: { flexGrow: 0, flexShrink: 0, flexBasis: '300px' },
          }}
          allowDeselect={false}
          placeholder="Pick value"
          value={viewModel.subscriptionKind}
          onChange={(value) =>
            viewModel.setSubscriptionKind(value as SubscriptionKind)
          }
          data={[
            { label: 'All subscriptions', value: 'all' },
            { label: 'Paid subscriptions', value: 'paid' },
            { label: 'Free subscriptions', value: 'free' },
            { label: 'Free trial subscriptions', value: 'free_trial' },
            { label: 'App Sumo subscriptions', value: 'app_sumo' },
          ]}
        />
      </Group>
      <Space h="sm" />
      <Box pos="relative">
        <Table striped highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Owner email</Table.Th>
              <Table.Th>Setup</Table.Th>
              <Table.Th>Signatures installation</Table.Th>
              <Table.Th colSpan={2}>Subscription</Table.Th>
              <Table.Th>Created at</Table.Th>
              <Table.Th w="180px" ta="center">
                Actions
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {viewModel.workspaces.map((workspace) => (
              <WorkspacesListItem
                key={`workspace-${workspace.uuid}`}
                workspace={workspace}
                viewModel={viewModel}
              />
            ))}
          </Table.Tbody>
        </Table>
        <LoadingOverlay visible={viewModel.loading} />
      </Box>
      <Space h="sm" />
      {viewModel.pagination && (
        <Pagination
          total={viewModel.pagination.total_pages || 0}
          value={viewModel.pagination.current_page || 1}
          onChange={(page) => viewModel.loadPage(page)}
          mt="sm"
        />
      )}
    </>
  )
})
