import { Text } from '@mantine/core'
import { Pagination } from '@scribe/types/src/admin'
import autoBind from 'auto-bind'
import engine from 'engine'
import EnterpriseCheckoutModel from 'engine/enterpriseCheckouts/EnterpriseCheckoutModel'
import { debounce } from 'lodash'
import { makeAutoObservable, reaction } from 'mobx'
import { confirmModal } from 'utils/confirmation'

const perPage = 10

export default class EnterpriseCheckoutsListViewModel {
  private _enterpriseCheckouts: EnterpriseCheckoutModel[] = []
  private _pagination: Pagination | null = null
  private _searchQuery: string = ''
  private _debouncedSearchQuery: string = ''
  private _loading: boolean = false

  constructor() {
    makeAutoObservable(this)
    autoBind(this)

    reaction(
      () => [this._debouncedSearchQuery],
      () => {
        this.loadPage(1)
      },
    )
  }

  public async loadPage(page: number) {
    this._loading = true
    const response =
      await engine.enterpriseCheckoutsEngine.getEnterpriseCheckouts({
        page: page,
        per_page: perPage,
        search_term: this._debouncedSearchQuery,
      })
    if (response) {
      this._enterpriseCheckouts = response.enterpriseCheckouts
      this._pagination = response.pagination
    }
    this._loading = false
  }

  public get loading() {
    return this._loading
  }

  public setSearchQuery(query: string) {
    this._searchQuery = query
    this.debouncedSetSearchQuery()
  }

  private debouncedSetSearchQuery = debounce(() => {
    this._debouncedSearchQuery = this._searchQuery
  }, 500)

  public get searchQuery() {
    return this._searchQuery
  }

  public get enterpriseCheckouts() {
    return this._enterpriseCheckouts
  }

  public get pagination() {
    return this._pagination
  }

  public async deleteEnterpriseCheckout(
    enterpriseCheckout: EnterpriseCheckoutModel,
  ) {
    confirmModal({
      title: 'Please confirm your action',
      content: (
        <Text size="sm">
          Are you sure you want to delete this enterprise checkout? ? This
          action is irreversible.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        const response =
          await engine.enterpriseCheckoutsEngine.deleteEnterpriseCheckout(
            enterpriseCheckout.id,
          )
        if (response) {
          this._enterpriseCheckouts = this._enterpriseCheckouts.filter(
            (e) => e.uuid !== enterpriseCheckout.uuid,
          )
        }
      },
    })
  }
}
