import { generateColors } from '@mantine/colors-generator'
import { MantineProvider, createTheme } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router'
import NavigationService from 'services/NavigationService'

const theme = createTheme({
  primaryColor: 'scribe-primary',
  colors: {
    'scribe-primary': generateColors('#FF3D00'),
  },
  cursorType: 'pointer',
  defaultRadius: 'md',
  components: {
    InputWrapper: {
      styles: {
        label: {
          paddingBottom: '5px',
        },
      },
    },
  },
})

export default function ApplicationContainer() {
  const navigate = useNavigate()

  useEffect(() => {
    NavigationService.setNavigateFunction(navigate)
  }, [navigate])

  return (
    <>
      <MantineProvider defaultColorScheme="dark" theme={theme}>
        <Notifications />
        <ModalsProvider>
          <Outlet />
        </ModalsProvider>
      </MantineProvider>
    </>
  )
}
