import { runInAction } from 'mobx'

export function mergeValuesToModel<T>(model: T, values: Partial<T>): void {
  runInAction(() => {
    ObjectKeys(values).forEach((key) => {
      model[key] = values[key] as T[typeof key]
    })
  })
}

export function ObjectKeys<T extends Record<string, unknown>>(
  obj: T,
): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>
}

export function updateMapWithList<
  Entity extends {
    uuid: string
    setAttributes: (attributes: Attributes) => void
  },
  Attributes extends { uuid: string | null },
>(
  map: Map<string, Entity>,
  list: Attributes[],
  createItem: (attributes: Attributes) => Entity,
): Entity[] {
  const result: Entity[] = []
  runInAction(() => {
    list.forEach((item) => {
      if (!item.uuid) {
        return
      }
      const existingItem = map.get(item.uuid)
      if (existingItem) {
        existingItem.setAttributes(item)
        result.push(existingItem)
      } else {
        const newItem = createItem(item)
        map.set(item.uuid, newItem) // With model
        result.push(newItem)
      }
    })
  })
  return result
}

export function replaceMapWithList<
  Entity extends {
    uuid: string
    setAttributes: (attributes: Attributes) => void
    persisted: boolean
  },
  Attributes extends { uuid: string | null },
>(
  map: Map<string, Entity>,
  list: Attributes[],
  createItem: (attributes: Attributes) => Entity,
): void {
  runInAction(() => {
    map.forEach((item) => {
      if (!item.persisted) {
        map.delete(item.uuid)
      }
    })
    list.forEach((item) => {
      if (item.uuid) {
        map.set(item.uuid, createItem(item))
      }
    })
  })
}
