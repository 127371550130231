import { Admin } from '@scribe/types/src/admin'
import ApiService from 'services/ApiService'
import qs from 'qs'

export default class SignatureTemplatesTransportLayer {
  public async getSignatureTemplates(
    params: Admin.SignatureTemplatesIndex.RequestQuery,
  ): Promise<Admin.SignatureTemplatesIndex.ResponseBody | null> {
    const response =
      await ApiService.get<Admin.SignatureTemplatesIndex.ResponseBody>(
        `/signature_templates?${qs.stringify(params)}`,
      )
    if (response.outcome === 'success') {
      return response.data
    }
    return null
  }
}
