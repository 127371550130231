import { Button, Modal, Space } from '@mantine/core'
import SubscriptionModel from 'engine/workspaces/SubscriptionModel'
import { observer } from 'mobx-react-lite'
import { DateInput } from '@mantine/dates'
import { useState } from 'react'
import dayjs from 'dayjs'
import NotificationService from 'services/NotificationService'

type WorkspacesListItemExtendFreeTrialModalProps = {
  opened: boolean
  onClose: () => void
  subscription: SubscriptionModel
}

export default observer(function WorkspacesListItemExtendFreeTrialModal(
  props: WorkspacesListItemExtendFreeTrialModalProps,
) {
  const [value, setValue] = useState<Date | null>(null)
  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      title={
        props.subscription?.kind === 'free_trial'
          ? 'Extend trial'
          : 'New free trial'
      }
      centered
    >
      <DateInput
        value={value}
        onChange={setValue}
        label="End date"
        placeholder="End date"
        minDate={dayjs().add(7, 'day').toDate()}
      />
      <Space h="md" />
      <Button
        onClick={() => {
          if (value) {
            props.subscription.extendFreeTrial(dayjs(value))
            NotificationService.success('Trial extended')
            props.onClose()
          }
        }}
      >
        Extend
      </Button>
    </Modal>
  )
})
