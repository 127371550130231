import { Divider, Drawer, Group, Table, Text, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconFileDownload, IconLink, IconSend } from '@tabler/icons-react'
import EnterpriseCheckoutsForm from 'components/enterpriseCheckouts/EnterpriseCheckoutsForm'
import EnterpriseCheckoutsFormViewModel from 'components/enterpriseCheckouts/EnterpriseCheckoutsFormViewModel'
import EnterpriseCheckoutsListViewModel from 'components/enterpriseCheckouts/EnterpriseCheckoutsListViewModel'
import EnterpriseCheckoutsSendContractForm from 'components/enterpriseCheckouts/EnterpriseCheckoutsSendContractForm'
import EnterpriseCheckoutsSendContractFormViewModel from 'components/enterpriseCheckouts/EnterpriseCheckoutsSendContractFormViewModel'
import TableActionIcon from 'components/ui/TableActionIcon/TableActionIcon'
import EnterpriseCheckoutModel from 'engine/enterpriseCheckouts/EnterpriseCheckoutModel'
import { capitalize } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import NotificationService from 'services/NotificationService'
import appConfig from 'utils/appConfig'
import { documentsUrl } from 'utils/utils'

type EnterpriseCheckoutsListItemProps = {
  viewModel: EnterpriseCheckoutsListViewModel
  enterpriseCheckout: EnterpriseCheckoutModel
}

export default observer(function EnterpriseCheckoutsListItem(
  props: EnterpriseCheckoutsListItemProps,
) {
  const [editFormOpened, { open: openEditForm, close: closeEditForm }] =
    useDisclosure(false)

  const [
    sendContractFormOpened,
    { open: openSendContractForm, close: closeSendContractForm },
  ] = useDisclosure(false)

  const viewModel = useMemo(
    () => new EnterpriseCheckoutsFormViewModel(props.enterpriseCheckout.uuid),
    [props.enterpriseCheckout.uuid],
  )
  const sendContractFormViewModel = useMemo(
    () =>
      new EnterpriseCheckoutsSendContractFormViewModel(
        props.enterpriseCheckout.uuid,
      ),
    [props.enterpriseCheckout.uuid],
  )

  useEffect(() => {
    if (editFormOpened) {
      viewModel.getCoupons()
    }
  }, [editFormOpened, viewModel])

  return (
    <>
      <Table.Tr>
        <Table.Td>{props.enterpriseCheckout.companyName}</Table.Td>
        <Table.Td>{props.enterpriseCheckout.firstName}</Table.Td>
        <Table.Td>{props.enterpriseCheckout.lastName}</Table.Td>
        <Table.Td>{props.enterpriseCheckout.email}</Table.Td>
        <Table.Td>{props.enterpriseCheckout.seatCount} users</Table.Td>
        <Table.Td>
          {capitalize(props.enterpriseCheckout.plan)}{' '}
          {props.enterpriseCheckout.billingInterval}
        </Table.Td>
        <Table.Td>
          {props.enterpriseCheckout.contractSentAt &&
            !props.enterpriseCheckout.contractSignedAt && (
              <Tooltip
                label={
                  <Text size="sm">
                    Sent on{' '}
                    {props.enterpriseCheckout.contractSentAt?.format(
                      'DD/MM/YYYY HH:mm',
                    )}
                  </Text>
                }
              >
                <Text size="sm" c="orange">
                  Sent
                </Text>
              </Tooltip>
            )}
          {props.enterpriseCheckout.contractSentAt &&
            props.enterpriseCheckout.contractSignedAt && (
              <Tooltip
                label={
                  <Text size="sm">
                    Signed on{' '}
                    {props.enterpriseCheckout.contractSignedAt?.format(
                      'DD/MM/YYYY HH:mm',
                    )}
                  </Text>
                }
              >
                <Text size="sm" c="green">
                  Signed
                </Text>
              </Tooltip>
            )}
          {!props.enterpriseCheckout.contractSentAt && (
            <Text size="sm" c="dimmed">
              Not sent
            </Text>
          )}
        </Table.Td>
        <Table.Td>
          {!props.enterpriseCheckout.activatedAt && (
            <Text size="sm" c="dimmed">
              Not activated
            </Text>
          )}
          {props.enterpriseCheckout.activatedAt && (
            <Tooltip
              label={
                <Text size="sm">
                  Activated on{' '}
                  {props.enterpriseCheckout.activatedAt?.format(
                    'DD/MM/YYYY HH:mm',
                  )}
                </Text>
              }
            >
              <Text size="sm" c="green">
                Activated
              </Text>
            </Tooltip>
          )}
        </Table.Td>
        <Table.Td>
          <Group gap="xs" align="flex-end">
            <TableActionIcon
              iconComponent={IconFileDownload}
              tooltipLabel={'Download contract'}
              variant="primary"
              onClick={() => {
                window.open(
                  documentsUrl(
                    `/enterprise_checkout_contracts/${props.enterpriseCheckout.id}`,
                  ),
                )
              }}
            />
            <TableActionIcon
              iconComponent={IconSend}
              tooltipLabel={'Send contract'}
              variant="primary"
              onClick={openSendContractForm}
              disabled={!!props.enterpriseCheckout.contractSentAt}
            />
            <Divider orientation="vertical" />
            <TableActionIcon
              iconComponent={IconLink}
              tooltipLabel={'Copy url'}
              variant="primary"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${appConfig.PROTOCOL}://${appConfig.APP_HOST}/enterprise-checkout/${props.enterpriseCheckout.uuid}`,
                )
                NotificationService.success('Url copied to clipboard')
              }}
            />
            <TableActionIcon
              icon="edit"
              tooltipLabel={'Edit'}
              variant="primary"
              onClick={openEditForm}
              disabled={!!props.enterpriseCheckout.contractSentAt}
            />
            <Divider orientation="vertical" />
            <TableActionIcon
              icon="delete"
              tooltipLabel={'Delete'}
              variant="danger"
              onClick={() => {
                props.viewModel.deleteEnterpriseCheckout(
                  props.enterpriseCheckout,
                )
              }}
              disabled={!!props.enterpriseCheckout.contractSentAt}
            />
          </Group>
        </Table.Td>
      </Table.Tr>

      <Drawer
        opened={editFormOpened}
        onClose={closeEditForm}
        position="right"
        size="xl"
        title="Edit enterprise checkout"
      >
        {editFormOpened && (
          <EnterpriseCheckoutsForm
            viewModel={viewModel}
            onSuccess={() => {
              closeEditForm()
              props.viewModel.loadPage(1)
            }}
          />
        )}
      </Drawer>

      <Drawer
        opened={sendContractFormOpened}
        onClose={closeSendContractForm}
        position="right"
        size="xl"
        title="Send contract"
      >
        <EnterpriseCheckoutsSendContractForm
          viewModel={sendContractFormViewModel}
          onSuccess={() => {
            closeSendContractForm()
          }}
        />
      </Drawer>
    </>
  )
})
