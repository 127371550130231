import engine from 'engine'
import appConfig from 'utils/appConfig'

export function numberToCurrency(amount: number, currency = 'EUR') {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)
}

export function documentsUrl(kind: string, format?: string) {
  return `${appConfig.DOCUMENTS_BASE_URL}${kind}.${format ?? 'pdf'}?access_token=${engine.sessionEngine.accessToken}`
}
