import WorkspaceModel from 'engine/workspaces/WorkspaceModel'
import { Box, Group, Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { head } from 'lodash'
import { IconCheck, IconX } from '@tabler/icons-react'

type WorkspacesListItemSetupProps = {
  workspace: WorkspaceModel
}

export default observer(function WorkspacesListItemSetupProps(
  props: WorkspacesListItemSetupProps,
) {
  const dataSourceIntegration =
    props.workspace.dataSourceIntegrations.find(
      (integration) => integration.active,
    ) || head(props.workspace.dataSourceIntegrations)

  const signatureInstallationDestinationIntegration =
    props.workspace.signatureInstallationDestinationIntegrations.find(
      (integration) => integration.active,
    ) || head(props.workspace.signatureInstallationDestinationIntegrations)

  return (
    <Text size="xs" component="div">
      <Integration
        integration={dataSourceIntegration}
        placeholder="No data source integration"
      />
      <Integration
        integration={signatureInstallationDestinationIntegration}
        placeholder="No signature installation destination integration"
      />
      <Group gap="xs">
        {props.workspace.firstSignatureInstalledAt && (
          <>
            <IconCheck color={'green'} size={18} />
            <Box>
              First signature installed at{' '}
              {props.workspace.firstSignatureInstalledAt.format('DD/MM/YYYY')}
            </Box>
          </>
        )}
        {!props.workspace.firstSignatureInstalledAt && (
          <>
            <IconX color="grey" size={18} />
            <Box>No signature installed</Box>
          </>
        )}
      </Group>
    </Text>
  )
})

function Integration(props: {
  integration: { name: string; active: boolean } | undefined
  placeholder: string
}) {
  return (
    <Group gap="xs">
      {props.integration && (
        <IconCheck
          color={props.integration.active ? 'green' : 'orange'}
          size={18}
        />
      )}
      {!props.integration && <IconX color="grey" size={18} />}
      <Box>
        {props.integration ? props.integration.name : props.placeholder}
      </Box>
    </Group>
  )
}
