import {
  Button,
  Fieldset,
  Grid,
  Group,
  NumberInput,
  Radio,
  Select,
  Space,
  TagsInput,
  TextInput,
} from '@mantine/core'
import EnterpriseCheckoutsFormViewModel from 'components/enterpriseCheckouts/EnterpriseCheckoutsFormViewModel'
import CouponInput from 'components/ui/couponInput/CouponInput'
import { getNames } from 'i18n-iso-countries'
import { observer } from 'mobx-react-lite'

type EnterpriseCheckoutsFormProps = {
  viewModel: EnterpriseCheckoutsFormViewModel
  onSuccess?: () => void
}

export default observer(function EnterpriseCheckoutsForm(
  props: EnterpriseCheckoutsFormProps,
) {
  return (
    <>
      <Fieldset legend="Lead information">
        <Grid gutter="sm">
          <Grid.Col span={6}>
            <TextInput
              label="First name"
              value={props.viewModel.firstName}
              onChange={(e) => props.viewModel.setFirstName(e.target.value)}
              error={props.viewModel.firstNameError}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Last name"
              value={props.viewModel.lastName}
              onChange={(e) => props.viewModel.setLastName(e.target.value)}
              error={props.viewModel.lastNameError}
            />
          </Grid.Col>
        </Grid>
        <Grid gutter="sm">
          <Grid.Col span={6}>
            <TextInput
              label="Job position"
              value={props.viewModel.jobPosition}
              onChange={(e) => props.viewModel.setJobPosition(e.target.value)}
              error={props.viewModel.jobPositionError}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Email"
              value={props.viewModel.email}
              onChange={(e) => props.viewModel.setEmail(e.target.value)}
              error={props.viewModel.emailError}
            />
          </Grid.Col>
        </Grid>
      </Fieldset>
      <Space h="xl" />
      <Fieldset legend="Plan information">
        <Grid gutter="sm">
          <Grid.Col span={6}>
            <Radio.Group
              label="Plan"
              value={props.viewModel.plan}
              onChange={handlePlanChange}
              error={props.viewModel.planError}
            >
              <Group mt="xs">
                <Radio value="enterprise" label="Enterprise" />
                <Radio value="business" label="Business" />
              </Group>
            </Radio.Group>
          </Grid.Col>
          <Grid.Col span={6}>
            <Radio.Group
              label="Billing interval"
              value={props.viewModel.billingInterval}
              onChange={handleBillingIntervalChange}
              error={props.viewModel.billingIntervalError}
            >
              <Group mt="xs">
                <Radio value="yearly" label="Yearly" />
                <Radio value="monthly" label="Monthly" />
              </Group>
            </Radio.Group>
          </Grid.Col>
        </Grid>
        <Space h="sm" />
        <Grid gutter="sm">
          <Grid.Col span={6}>
            <NumberInput
              label="Seats count"
              value={props.viewModel.seatCount}
              onChange={(value) =>
                props.viewModel.setSeatCount(value ? parseInt(`${value}`) : 0)
              }
              error={props.viewModel.seatCountError}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <CouponInput
              disabled={props.viewModel.couponsLoading}
              value={props.viewModel.stripeCouponId}
              onChange={props.viewModel.setStripeCouponId}
              error={props.viewModel.stripeCouponIdError}
            />
          </Grid.Col>
        </Grid>
        <Space h="sm" />
        <Grid gutter="sm">
          <Grid.Col span={6}>
            <Radio.Group
              label="Currency"
              value={props.viewModel.currencyCode}
              onChange={handleCurrencyCodeChange}
              error={props.viewModel.currencyCodeError}
            >
              <Group mt="xs">
                <Radio value="USD" label="USD" />
                <Radio value="EUR" label="EUR" />
              </Group>
            </Radio.Group>
          </Grid.Col>
        </Grid>
      </Fieldset>
      <Space h="xl" />
      <Fieldset legend="Company information">
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="Company name"
              value={props.viewModel.companyName}
              onChange={(e) => props.viewModel.setCompanyName(e.target.value)}
              error={props.viewModel.companyNameError}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="Street address"
              value={props.viewModel.streetName}
              onChange={(e) => props.viewModel.setStreetName(e.target.value)}
              error={props.viewModel.streetNameError}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Street number"
              value={props.viewModel.streetNumber}
              onChange={(e) => props.viewModel.setStreetNumber(e.target.value)}
              error={props.viewModel.streetNumberError}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="Zip code"
              value={props.viewModel.zipCode}
              onChange={(e) => props.viewModel.setZipCode(e.target.value)}
              error={props.viewModel.zipCodeError}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="City"
              value={props.viewModel.city}
              onChange={(e) => props.viewModel.setCity(e.target.value)}
              error={props.viewModel.cityError}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <Select
              label="Country"
              value={props.viewModel.countryCode}
              onChange={(value) => props.viewModel.setCountryCode(value)}
              error={props.viewModel.countryCodeError}
              data={countryOptions()}
              searchable
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TagsInput
              data={[]}
              value={props.viewModel.emails}
              onChange={props.viewModel.setEmails}
              label="Billing emails"
              placeholder={'Enter emails separated by commas'}
              error={props.viewModel.emailsError}
            />
          </Grid.Col>
        </Grid>
      </Fieldset>
      <Group mt="xl">
        <Button onClick={handleSave} loading={props.viewModel.loading}>
          {props.viewModel.enterpriseCheckout?.persisted
            ? 'Mettre à jour'
            : 'Créer'}
        </Button>
      </Group>
    </>
  )

  function handleSave() {
    props.viewModel.save(props.onSuccess)
  }

  function handlePlanChange(value: string) {
    if (value === 'enterprise' || value === 'business') {
      props.viewModel.setPlan(value)
    }
  }

  function handleBillingIntervalChange(value: string) {
    if (value === 'yearly' || value === 'monthly') {
      props.viewModel.setBillingInterval(value)
    }
  }

  function handleCurrencyCodeChange(value: string) {
    if (value === 'USD' || value === 'EUR') {
      props.viewModel.setCurrencyCode(value)
    }
  }

  function countryOptions() {
    return Object.entries(
      getNames('en', {
        select: 'official',
      }),
    ).map((countryCode) => ({
      value: countryCode[0],
      label: countryCode[1],
    }))
  }
})
