import { ActionIcon, Tooltip } from '@mantine/core'
import {
  Icon,
  IconArchive,
  IconArchiveOff,
  IconCopy,
  IconKey,
  IconPencil,
  IconTrash,
} from '@tabler/icons-react'

type Variant = 'primary' | 'danger' | 'success' | 'warning'
type IconName =
  | 'edit'
  | 'delete'
  | 'duplicate'
  | 'archive'
  | 'unarchive'
  | 'takeover'

type BaseTableActionButtonProps = {
  variant?: Variant
  onClick: () => void
  disabled?: boolean
  tooltipLabel: React.ReactNode
  disableTooltip?: boolean
}

type TableActionButtonProps =
  | (BaseTableActionButtonProps & {
      icon: IconName
    })
  | (BaseTableActionButtonProps & {
      iconComponent: Icon
    })

const iconProps = { size: '20', stroke: 1.5 }

export default function TableActionIcon(props: TableActionButtonProps) {
  const { disableTooltip = true } = props
  return (
    <Tooltip
      label={props.tooltipLabel}
      disabled={props.disabled && disableTooltip}
    >
      <ActionIcon
        variant="filled"
        color={color()}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {'iconComponent' in props && <props.iconComponent {...iconProps} />}
        {'icon' in props && iconComponent()}
      </ActionIcon>
    </Tooltip>
  )

  function color() {
    return {
      danger: 'red',
      success: 'green',
      warning: 'orange',
      primary: 'gray',
    }[props.variant || 'primary']
  }

  function iconComponent() {
    if ('icon' in props) {
      if (props.icon === 'delete') {
        return <IconTrash {...iconProps} />
      } else if (props.icon === 'edit') {
        return <IconPencil {...iconProps} />
      } else if (props.icon === 'duplicate') {
        return <IconCopy {...iconProps} />
      } else if (props.icon === 'archive') {
        return <IconArchive {...iconProps} />
      } else if (props.icon === 'unarchive') {
        return <IconArchiveOff {...iconProps} />
      } else if (props.icon === 'takeover') {
        return <IconKey {...iconProps} />
      }
    }
    return null
  }
}
