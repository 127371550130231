import { Button, Grid, Group, TextInput } from '@mantine/core'
import EnterpriseCheckoutsSendContractFormViewModel from 'components/enterpriseCheckouts/EnterpriseCheckoutsSendContractFormViewModel'
import { observer } from 'mobx-react-lite'

type EnterpriseCheckoutsSendContractFormProps = {
  viewModel: EnterpriseCheckoutsSendContractFormViewModel
  onSuccess?: () => void
}

export default observer(function EnterpriseCheckoutsSendContractForm(
  props: EnterpriseCheckoutsSendContractFormProps,
) {
  return (
    <>
      <Grid gutter="sm">
        <Grid.Col span={6}>
          <TextInput
            label="Email"
            value={props.viewModel.email}
            onChange={(e) => props.viewModel.setEmail(e.target.value)}
            error={props.viewModel.emailError}
          />
        </Grid.Col>
      </Grid>
      <Grid gutter="sm">
        <Grid.Col span={6}>
          <TextInput
            label="First name"
            value={props.viewModel.firstName}
            onChange={(e) => props.viewModel.setFirstName(e.target.value)}
            error={props.viewModel.firstNameError}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Last name"
            value={props.viewModel.lastName}
            onChange={(e) => props.viewModel.setLastName(e.target.value)}
            error={props.viewModel.lastNameError}
          />
        </Grid.Col>
      </Grid>
      <Group mt="xl">
        <Button onClick={handleSave} loading={props.viewModel.loading}>
          Send contract
        </Button>
      </Group>
    </>
  )

  function handleSave() {
    props.viewModel.save(props.onSuccess)
  }
})
