import { Admin, Subscription, Workspace } from '@scribe/types/src/admin'
import autoBind from 'auto-bind'
import { EngineStatus } from 'engine/BaseEngine'
import { replaceMapWithList, updateMapWithList } from 'engine/mobxUtils'
import SubscriptionModel from 'engine/workspaces/SubscriptionModel'
import SubscriptionsTransportLayer from 'engine/workspaces/SubscriptionsTransportLayer'
import WorkspaceModel from 'engine/workspaces/WorkspaceModel'
import WorkspacesTransportLayer from 'engine/workspaces/WorkspacesTransportLayer'
import compact from 'lodash/compact'
import { makeAutoObservable } from 'mobx'

export default class WorkspacesEngine {
  public engineStatus: EngineStatus = 'off'

  private _workspacesTransportLayer = new WorkspacesTransportLayer()
  private _subscriptionsTransportLayer = new SubscriptionsTransportLayer()

  private _workspaces = new Map<string, WorkspaceModel>()
  private _subscriptions = new Map<string, SubscriptionModel>()

  constructor() {
    makeAutoObservable(this)
    autoBind(this)
  }

  public async start(): Promise<EngineStatus> {
    this.engineStatus = 'running'
    return this.engineStatus
  }

  public async stop(): Promise<EngineStatus> {
    this.engineStatus = 'off'
    return this.engineStatus
  }

  public subscriptionsForWorkspace(workspace: WorkspaceModel) {
    return Array.from(this._subscriptions.values()).filter(
      (subscription) => subscription.workspaceUuid === workspace.uuid,
    )
  }

  public async deleteWorkspace(workspace: WorkspaceModel) {
    const response = await this._workspacesTransportLayer.deleteWorkspace(
      workspace.id,
    )
    if (response && response.outcome === 'success') {
      workspace.markAsDeleted()
      this._workspaces.delete(workspace.uuid)
    }
    return response
  }

  public async getWorkspaces(params: Admin.WorkspacesIndex.RequestQuery) {
    const response = await this._workspacesTransportLayer.getWorkspaces(params)
    if (response) {
      updateMapWithList(
        this._workspaces,
        response.workspaces,
        this._buildModel.bind(this),
      )
      response.workspaces.forEach((workspace) => {
        updateMapWithList(
          this._subscriptions,
          workspace.subscriptions,
          this._buildSubscriptionModel.bind(this),
        )
      })
      const workspaces = response.workspaces.map((workspace) => {
        return this._workspaces.get(workspace.uuid)
      })
      return {
        workspaces: compact(workspaces),
        pagination: response.pagination,
      }
    }
    return null
  }

  public buildNewSubscriptionModel(subscription: Partial<Subscription>) {
    const model = new SubscriptionModel(
      this._subscriptionsTransportLayer,
      subscription,
    )
    this._subscriptions.set(model.uuid, model)
    return model
  }

  private _buildModel(workspace: Partial<Workspace>) {
    return new WorkspaceModel(this._workspacesTransportLayer, workspace)
  }

  private _buildSubscriptionModel(subscription: Partial<Subscription>) {
    return new SubscriptionModel(
      this._subscriptionsTransportLayer,
      subscription,
    )
  }
}
