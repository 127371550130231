import {
  Button,
  Divider,
  Group,
  Popover,
  Progress,
  Table,
  Text,
  TextInput,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import { IconRefresh, IconRosetteDiscount } from '@tabler/icons-react'
import TableActionIcon from 'components/ui/TableActionIcon/TableActionIcon'
import WorkspacesListItemCouponModal from 'components/workspaces/WorkspacesListItemCouponModal'
import WorkspacesListItemExtendFreeTrialModal from 'components/workspaces/WorkspacesListItemExtendFreeTrialModal'
import WorkspacesListItemSetup from 'components/workspaces/WorkspacesListItemSetup'
import WorkspacesListViewModel from 'components/workspaces/WorkspacesListViewModel'
import WorkspaceModel, {
  setupStepsCount,
} from 'engine/workspaces/WorkspaceModel'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { numberToCurrency } from 'utils/utils'

type WorkspacesListItemProps = {
  viewModel: WorkspacesListViewModel
  workspace: WorkspaceModel
}

export default observer(function WorkspacesListItem(
  props: WorkspacesListItemProps,
) {
  const [opened, { close, open }] = useDisclosure(false)
  const [
    extendFreeTrialModalOpened,
    { close: closeExtendFreeTrialModal, open: openExtendFreeTrialModal },
  ] = useDisclosure(false)

  const [
    couponModalOpened,
    { close: closeCouponModal, open: openCouponModal },
  ] = useDisclosure(false)

  return (
    <>
      <Table.Tr>
        <Table.Td>{props.workspace.name}</Table.Td>
        <Table.Td>{props.workspace.ownerUserEmail}</Table.Td>
        <Table.Td>
          <Popover
            width={350}
            position="left"
            withArrow
            shadow="md"
            opened={opened}
          >
            <Popover.Target>
              <Group gap="xs" onMouseEnter={open} onMouseLeave={close}>
                <Progress
                  w="100px"
                  color="yellow"
                  value={
                    (props.workspace.setupStepsCount / setupStepsCount) * 100
                  }
                />{' '}
                <Text size="xs">
                  {props.workspace.setupStepsCount} / {setupStepsCount}
                </Text>
              </Group>
            </Popover.Target>
            <Popover.Dropdown>
              <WorkspacesListItemSetup workspace={props.workspace} />
            </Popover.Dropdown>
          </Popover>
        </Table.Td>
        <Table.Td>
          {props.workspace.installedTeammatesCount} /{' '}
          {props.workspace.teammatesCount}
        </Table.Td>
        <Table.Td>
          {props.workspace.appSumoSubscription ? 'App Sumo' : ''}
          {props.workspace.currentSubscription &&
            props.workspace.appSumoSubscription &&
            ' and '}
          {props.workspace.currentSubscription && (
            <>{props.workspace.currentSubscription.kindText}</>
          )}
          {props.workspace.currentSubscription?.kind === 'free_trial' && (
            <>
              <Text size="xs">
                {props.workspace.currentSubscription.startsAt?.format(
                  'DD/MM/YYYY',
                )}{' '}
                -{' '}
                {props.workspace.currentSubscription.endsAt?.format(
                  'DD/MM/YYYY',
                )}
              </Text>
            </>
          )}
        </Table.Td>
        <Table.Td>
          {props.workspace.currentSubscription &&
            props.workspace.currentSubscription.kind === 'paid' && (
              <>
                {' '}
                {props.workspace.currentSubscription.amountCents &&
                  numberToCurrency(
                    props.workspace.currentSubscription.amountCents / 100,
                    props.workspace.currencyCode,
                  )}
                {' / '}
                {props.workspace.currentSubscription.billingIntervalUnit}
              </>
            )}
        </Table.Td>
        <Table.Td>{props.workspace.createdAt?.format('DD/MM/YYYY')}</Table.Td>
        <Table.Td>
          <Group gap="xs" align="flex-end">
            <TableActionIcon
              tooltipLabel={
                props.workspace.currentSubscription?.kind === 'free_trial'
                  ? 'Extend trial'
                  : 'New free trial'
              }
              iconComponent={IconRefresh}
              onClick={openExtendFreeTrialModal}
              disabled={
                !(
                  props.workspace.currentSubscription?.kind === 'free_trial' ||
                  props.workspace.currentSubscription?.kind === 'free'
                )
              }
            />
            <TableActionIcon
              tooltipLabel={
                props.workspace.stripeCouponId
                  ? props.workspace.stripeCouponId
                  : 'Apply coupon'
              }
              iconComponent={IconRosetteDiscount}
              onClick={openCouponModal}
              disabled={!!props.workspace.stripeCouponId}
              disableTooltip={false}
            />
            <TableActionIcon
              icon="takeover"
              tooltipLabel={'Takeover'}
              variant="primary"
              onClick={() => props.viewModel.takeoverWorkspace(props.workspace)}
            />
            <Divider orientation="vertical" />
            <TableActionIcon
              icon="delete"
              tooltipLabel={'Delete'}
              variant="danger"
              onClick={() => {
                modals.open({
                  centered: true,
                  title: 'Delete workspace',
                  children: (
                    <ModalChildren
                      workspaceEmail={props.workspace.ownerUserEmail}
                      onConfirm={() => {
                        modals.closeAll()
                        props.viewModel.deleteWorkspace(props.workspace)
                      }}
                    />
                  ),
                })
              }}
            />
          </Group>
        </Table.Td>
      </Table.Tr>
      {props.workspace.currentSubscription && (
        <WorkspacesListItemExtendFreeTrialModal
          opened={extendFreeTrialModalOpened}
          onClose={closeExtendFreeTrialModal}
          subscription={props.workspace.currentSubscription}
        />
      )}
      <WorkspacesListItemCouponModal
        opened={couponModalOpened}
        onClose={closeCouponModal}
        workspace={props.workspace}
      />
    </>
  )
})

function ModalChildren({
  workspaceEmail,
  onConfirm,
}: {
  workspaceEmail: string
  onConfirm: () => void
}) {
  const [email, setEmail] = useState('')
  const deleteDisabled = email !== workspaceEmail

  return (
    <>
      <TextInput
        label="Enter the owner email"
        placeholder="Owner email"
        data-autofocus
        value={email}
        onChange={(e) => setEmail(e.currentTarget.value)}
      />
      <Button fullWidth onClick={onConfirm} disabled={deleteDisabled} mt="md">
        Delete workspace
      </Button>
    </>
  )
}
