import { Divider, Title } from '@mantine/core'
import WorkspacesList from 'components/workspaces/WorkspacesList'
import { Helmet } from 'react-helmet'

export default function WorkspacesScene() {
  return (
    <>
      <Helmet>
        <title>Workspaces | Scribe | Admin</title>
      </Helmet>
      <Title order={2}>Workspaces</Title>
      <Divider w="100%" size="sm" mt="sm" mb="sm" />
      <WorkspacesList />
    </>
  )
}
