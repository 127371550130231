import ApplicationAuthenticatedContainer from 'components/application/ApplicationAuthenticatedContainer'
import ApplicationContainer from 'components/application/ApplicationContainer'
import ApplicationContentContainer from 'components/application/ApplicationContentContainer'
import ApplicationNotAuthenticatedContainer from 'components/application/ApplicationNotAuthenticatedContainer'
import { Navigate, Route, Routes } from 'react-router-dom'
import { number, route } from 'react-router-typesafe-routes'
import Application404Scene from 'scenes/application/Application404Scene'
import ApplicationLoginScene from 'scenes/application/ApplicationLoginScene'
import ApplicationLogoutScene from 'scenes/application/ApplicationLogoutScene'
import AutoTakeoverScene from 'scenes/autoTakeover/AutoTakeoverScene'
import EnterpriseCheckoutScene from 'scenes/enterpriseCheckout/EnterpriseCheckoutScene'
import SignatureTemplatesScene from 'scenes/signatureTemplates/SignatureTemplatesScene'
import WorkspacesScene from 'scenes/workspaces/WorkspacesScene'

export const ROUTES = {
  ROOT: route({ path: '' }),
  LOGIN: route({ path: 'login' }),
  LOGOUT: route({ path: 'logout' }),
  WORKSPACES: route({ path: 'workspaces' }),
  SIGNATURE_TEMPLATES: route({ path: 'signature-templates' }),
  ENTERPRISE_CHECKOUT: route({ path: 'enterprise-checkout' }),
  AUTO_TAKEOVER: route({
    path: 'auto-takeover/:userId',
    params: { userId: number().defined() },
  }),
}

export default function ApplicationRoutes() {
  return (
    <Routes>
      <Route element={<ApplicationContainer />}>
        <Route element={<ApplicationNotAuthenticatedContainer />}>
          <Route
            path={ROUTES.LOGIN.$path()}
            element={<ApplicationLoginScene />}
          />
        </Route>
        <Route element={<ApplicationAuthenticatedContainer />}>
          <Route element={<ApplicationContentContainer />}>
            <Route
              path={ROUTES.ROOT.$path()}
              element={<Navigate to={ROUTES.WORKSPACES.$path()} />}
            />
            <Route
              path={ROUTES.WORKSPACES.$path()}
              element={<WorkspacesScene />}
            />
            <Route
              path={ROUTES.ENTERPRISE_CHECKOUT.$path()}
              element={<EnterpriseCheckoutScene />}
            />
            <Route
              path={ROUTES.SIGNATURE_TEMPLATES.$path()}
              element={<SignatureTemplatesScene />}
            />
            <Route
              path={ROUTES.AUTO_TAKEOVER.$path()}
              element={<AutoTakeoverScene />}
            />
          </Route>
          <Route
            path={ROUTES.LOGOUT.$path()}
            element={<ApplicationLogoutScene />}
          />
        </Route>
        <Route path="*" element={<Application404Scene />} />
      </Route>
    </Routes>
  )
}
