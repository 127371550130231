import autoBind from 'auto-bind'
import engine from 'engine'
import { makeAutoObservable } from 'mobx'
import NotificationService from 'services/NotificationService'

export default class EnterpriseCheckoutsSendContractFormViewModel {
  private _enterpriseCheckoutUuid: string
  private _loading: boolean = false

  private _firstName: string = ''
  private _lastName: string = ''
  private _email: string = ''

  private _firstNameError: string | null = null
  private _lastNameError: string | null = null
  private _emailError: string | null = null

  constructor(enterpriseCheckoutUuid: string) {
    makeAutoObservable(this)
    autoBind(this)
    this._enterpriseCheckoutUuid = enterpriseCheckoutUuid
    this._firstName = this.enterpriseCheckout?.firstName || ''
    this._lastName = this.enterpriseCheckout?.lastName || ''
    this._email = this.enterpriseCheckout?.email || ''
  }

  public get enterpriseCheckout() {
    return engine.enterpriseCheckoutsEngine.forUuid(
      this._enterpriseCheckoutUuid,
    )
  }

  public get loading() {
    return this._loading
  }

  public get firstName() {
    return this._firstName
  }

  public setFirstName(firstName: string) {
    this._firstName = firstName
  }

  public get lastName() {
    return this._lastName
  }

  public setLastName(lastName: string) {
    this._lastName = lastName
  }

  public get email() {
    return this._email
  }

  public setEmail(email: string) {
    this._email = email
  }

  public get firstNameError() {
    return this._firstNameError
  }

  public get lastNameError() {
    return this._lastNameError
  }

  public get emailError() {
    return this._emailError
  }

  public async save(onSuccess?: () => void) {
    console.log(this.enterpriseCheckout)
    if (!this.enterpriseCheckout) {
      return
    }
    this._loading = true
    this._firstNameError = null
    this._lastNameError = null
    this._emailError = null

    const attributes = {
      uuid: this.enterpriseCheckout.uuid,
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
    }
    const response = await this.enterpriseCheckout.sendContract(attributes)
    if (response && response.outcome === 'success') {
      NotificationService.success('Enterprise checkout contract sent')
      if (onSuccess) {
        onSuccess()
      }
    } else if (response) {
      NotificationService.error(
        'An error occurred while sending the enterprise checkout contract',
      )
      this._firstNameError = response.data.errors?.first_name?.message || null
      this._lastNameError = response.data.errors?.last_name?.message || null
      this._emailError = response.data.errors?.email?.message || null
    }
    this._loading = false
  }
}
