import { Admin } from '@scribe/types/src/admin'
import ApiService from 'services/ApiService'

export default class SubscriptionsTransportLayer {
  public async extendFreeTrial(subscriptionId: string, endsAt: string) {
    const response = await ApiService.put<
      Admin.SubscriptionsUpdate.RequestBody,
      Admin.SubscriptionsUpdate.ResponseBody,
      void
    >(`/subscriptions/${subscriptionId}`, {
      subscription: { ends_at: endsAt },
    })
    return response
  }
}
