import {
  Admin,
  EnterpriseCheckoutErrorResponse,
  EnterpriseCheckoutSendingErrorResponse,
} from '@scribe/types/src/admin'
import ApiService from 'services/ApiService'
import qs from 'qs'

export default class EnterpriseCheckoutsTransportLayer {
  public async getEnterpriseCheckouts(
    params: Admin.EnterpriseCheckoutsIndex.RequestQuery,
  ) {
    const response =
      await ApiService.get<Admin.EnterpriseCheckoutsIndex.ResponseBody>(
        `/enterprise_checkouts?${qs.stringify(params)}`,
      )
    if (response.outcome === 'success') {
      return response
    }
    return null
  }

  public async create(
    attributes: Admin.EnterpriseCheckoutsCreate.RequestBody['enterprise_checkout'],
  ) {
    const response = await ApiService.post<
      Admin.EnterpriseCheckoutsCreate.RequestBody,
      Admin.EnterpriseCheckoutsCreate.ResponseBody,
      EnterpriseCheckoutErrorResponse
    >('/enterprise_checkouts', {
      enterprise_checkout: attributes,
    })
    return response
  }

  public async update(
    id: number,
    attributes: Admin.EnterpriseCheckoutsUpdate.RequestBody['enterprise_checkout'],
  ) {
    const response = await ApiService.put<
      Admin.EnterpriseCheckoutsUpdate.RequestBody,
      Admin.EnterpriseCheckoutsUpdate.ResponseBody,
      EnterpriseCheckoutErrorResponse
    >(`/enterprise_checkouts/${id}`, {
      enterprise_checkout: attributes,
    })
    return response
  }

  public async sendContract(
    id: number,
    attributes: Admin.EnterpriseCheckoutsSendingCreate.RequestBody['enterprise_checkout'],
  ) {
    const response = await ApiService.post<
      Admin.EnterpriseCheckoutsSendingCreate.RequestBody,
      Admin.EnterpriseCheckoutsSendingCreate.ResponseBody,
      EnterpriseCheckoutSendingErrorResponse
    >(`/enterprise_checkouts/${id}/sending`, {
      enterprise_checkout: attributes,
    })
    return response
  }

  public async delete(id: number) {
    const response =
      await ApiService.delete<Admin.EnterpriseCheckoutsDelete.ResponseBody>(
        `/enterprise_checkouts/${id}`,
      )
    return response
  }

  public async getCoupons() {
    const response =
      await ApiService.get<Admin.CouponsIndex.ResponseBody>(`/coupons`)
    if (response.outcome === 'success') {
      return response
    }
    return null
  }
}
