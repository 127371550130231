import { Pagination } from '@scribe/types/src/admin'
import autoBind from 'auto-bind'
import engine from 'engine'
import SignatureTemplateModel from 'engine/signatureTemplates/SignatureTemplateModel'
import { debounce } from 'lodash'
import { makeAutoObservable, reaction } from 'mobx'

const perPage = 10

export default class SignatureTemplatesListViewModel {
  private _signatureTemplates: SignatureTemplateModel[] = []
  private _pagination: Pagination | null = null
  private _searchQuery: string = ''
  private _debouncedSearchQuery: string = ''
  private _loading: boolean = false

  constructor() {
    makeAutoObservable(this)
    autoBind(this)

    reaction(
      () => [this._debouncedSearchQuery],
      () => {
        this.loadPage(1)
      },
    )
  }

  public async loadPage(page: number) {
    this._loading = true
    const response =
      await engine.signatureTemplatesEngine.getSignatureTemplates({
        page: page,
        per_page: perPage,
        search_term: this._debouncedSearchQuery,
      })
    if (response) {
      this._signatureTemplates = response.signatureTemplates
      this._pagination = response.pagination
    }
    this._loading = false
  }

  public get loading() {
    return this._loading
  }

  public setSearchQuery(query: string) {
    this._searchQuery = query
    this.debouncedSetSearchQuery()
  }

  private debouncedSetSearchQuery = debounce(() => {
    this._debouncedSearchQuery = this._searchQuery
  }, 500)

  public get searchQuery() {
    return this._searchQuery
  }

  public get signatureTemplates() {
    return this._signatureTemplates
  }

  public get pagination() {
    return this._pagination
  }
}
