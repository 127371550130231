import {
  Box,
  Grid,
  Group,
  LoadingOverlay,
  Pagination,
  Space,
  TextInput,
} from '@mantine/core'
import { IconSearch, IconX } from '@tabler/icons-react'
import SignatureTemplatesListItem from 'components/signatureTemplates/SignatureTemplatesListItem'
import SignatureTemplatesListViewModel from 'components/signatureTemplates/SignatureTemplatesListViewModel'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

export default observer(function SignatureTemplatesList() {
  const viewModel = useMemo(() => new SignatureTemplatesListViewModel(), [])

  useEffect(() => {
    viewModel.loadPage(1)
  }, [viewModel])

  return (
    <>
      <Space h="sm" />
      <Group align="flex-end">
        <TextInput
          flex={1}
          variant="filled"
          size="sm"
          placeholder="Search for a workspace ..."
          leftSection={<IconSearch size={16} />}
          onChange={(event) => viewModel.setSearchQuery(event.target.value)}
          value={viewModel.searchQuery}
          rightSection={
            viewModel.searchQuery ? (
              <IconX
                size={16}
                onClick={() => viewModel.setSearchQuery('')}
                style={{ cursor: 'pointer' }}
              />
            ) : null
          }
        />
      </Group>
      <Space h="sm" />
      <Box pos="relative">
        <Grid>
          {viewModel.signatureTemplates.map((signatureTemplate) => (
            <Grid.Col
              span={6}
              key={`signature-template-${signatureTemplate.uuid}`}
            >
              <SignatureTemplatesListItem
                signatureTemplate={signatureTemplate}
                viewModel={viewModel}
              />
            </Grid.Col>
          ))}
        </Grid>
        <LoadingOverlay visible={viewModel.loading} />
      </Box>
      <Space h="sm" />
      {viewModel.pagination && (
        <Pagination
          total={viewModel.pagination.total_pages || 0}
          value={viewModel.pagination.current_page || 1}
          onChange={(page) => viewModel.loadPage(page)}
          mt="sm"
        />
      )}
      <Space h="xl" />
    </>
  )
})
