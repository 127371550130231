import {
  Box,
  Button,
  Group,
  NumberInput,
  Space,
  TextInput,
} from '@mantine/core'
import { Radio } from '@mantine/core'
import { Coupon } from '@scribe/types/src/admin'
import CouponsFormViewModel from 'components/coupons/CouponsFormViewModel'
import { observer } from 'mobx-react-lite'

type CouponsFormProps = {
  viewModel: CouponsFormViewModel
}

export default observer(function CouponsForm(props: CouponsFormProps) {
  return (
    <>
      <TextInput
        label="Name"
        value={props.viewModel.name}
        onChange={(e) => props.viewModel.setName(e.target.value)}
      />
      <Space h="sm" />
      <TextInput
        label="Id"
        value={props.viewModel.id}
        onChange={(e) => props.viewModel.setId(e.target.value)}
      />
      <Space h="sm" />
      <NumberInput
        label="Percent off"
        value={props.viewModel.percentOff}
        onChange={(value) =>
          props.viewModel.setPercentOff(value?.toString() ?? '')
        }
        min={1}
        max={100}
        rightSection="%"
      />
      <Space h="sm" />
      <Radio.Group
        label="Duration"
        value={props.viewModel.duration}
        onChange={handleDurationChange}
      >
        <Group mt="xs">
          <Radio value="once" label="Once" />
          <Radio value="forever" label="Forever" />
        </Group>
      </Radio.Group>
      <Box mt="xl">
        <Button
          onClick={props.viewModel.create}
          loading={props.viewModel.loading}
          disabled={!props.viewModel.isValid()}
        >
          Create coupon
        </Button>
      </Box>
    </>
  )

  function handleDurationChange(value: string) {
    props.viewModel.setDuration(value as Coupon['duration'])
  }
})
