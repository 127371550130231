import { SignatureTemplate } from '@scribe/types/src/admin'
import autoBind from 'auto-bind'
import dayjs from 'dayjs'
import BaseModel from 'engine/BaseModel'
import { mergeValuesToModel } from 'engine/mobxUtils'
import SignatureTemplatesTransportLayer from 'engine/signatureTemplates/SignatureTemplatesTransportLayer'
import { makeAutoObservable, observable } from 'mobx'
import { createViewModel, IViewModel } from 'mobx-utils'

function defaultAttributes(): SignatureTemplate {
  return {
    id: null,
    uuid: '',
    name: '',
    workspace_name: '',
    raw_html: '',
    created_at: '',
    updated_at: '',
  }
}

export default class SignatureTemplateModel implements BaseModel {
  private _signatureTemplate: SignatureTemplate & IViewModel<SignatureTemplate>
  private _signatureTemplatesTransportLayer: SignatureTemplatesTransportLayer
  private _persisted = false
  private _isSaving = false

  constructor(
    signatureTemplatesTransportLayer: SignatureTemplatesTransportLayer,
    attributes?: Partial<SignatureTemplate>,
  ) {
    makeAutoObservable(this)
    autoBind(this)
    this._signatureTemplate = createViewModel(
      observable({ ...defaultAttributes(), ...attributes }),
    )
    this._signatureTemplatesTransportLayer = signatureTemplatesTransportLayer
  }

  public get persisted() {
    return this._persisted
  }

  public get isSaving() {
    return this._isSaving
  }

  public get rawHtml() {
    return this._signatureTemplate.raw_html
  }

  public get name() {
    return this._signatureTemplate.name
  }

  public get workspaceName() {
    return this._signatureTemplate.workspace_name
  }

  public get id() {
    if (!this._signatureTemplate.id) {
      throw new Error('SignatureTemplate id is null')
    }
    return this._signatureTemplate.id
  }

  public get uuid() {
    if (this._signatureTemplate.uuid === '') {
      throw new Error('SignatureTemplate uuid is empty')
    }
    return this._signatureTemplate.uuid
  }

  public get createdAt() {
    if (!this._signatureTemplate.created_at) {
      return null
    }
    return dayjs(this._signatureTemplate.created_at)
  }

  public get updatedAt() {
    if (!this._signatureTemplate.updated_at) {
      return null
    }
    return dayjs(this._signatureTemplate.updated_at)
  }

  public setAttributes(signatureTemplate: SignatureTemplate) {
    if (
      !this.updatedAt ||
      this.updatedAt.isSameOrBefore(dayjs(signatureTemplate.updated_at))
    ) {
      mergeValuesToModel(this._signatureTemplate.model, signatureTemplate)
    }
  }
}
