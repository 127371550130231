import { Button, Container, Group, Text, Title } from '@mantine/core'
import { ROUTES } from 'components/application/ApplicationRoutes'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'

import classes from './Application404Scene.module.css'

export default function Application404Scene() {
  const navigate = useNavigate()
  return (
    <>
      <Helmet>
        <title>Page Not Found | Scribe | Admin</title>
      </Helmet>
      <Container className={classes.root}>
        <div className={classes.label}>404</div>
        <Title className={classes.title}>You have found a secret place.</Title>
        <Group justify="center" mt="lg">
          <Text
            c="dimmed"
            size="lg"
            ta="center"
            className={classes.description}
          >
            Unfortunately, this is only a 404 page. You may have mistyped the
            address, or the page has been moved to another URL.
          </Text>
        </Group>
        <Group justify="center" mt="lg">
          <Button
            variant="subtle"
            size="md"
            onClick={() => navigate(ROUTES.ROOT.$path())}
          >
            Take me back to home page
          </Button>
        </Group>
      </Container>
    </>
  )
}
