import autoBind from 'auto-bind'
import { AxiosResponse } from 'axios'
import BaseEngine, { EngineStatus } from 'engine/BaseEngine'
import SessionModel from 'engine/session/SessionModel'
import SessionTransportLayer from 'engine/session/SessionTransportLayer'
import Cookies from 'js-cookie'
import { makeAutoObservable, reaction } from 'mobx'
import appConfig from 'utils/appConfig'
import { Admin } from '@scribe/types/src/admin'
import { ROUTES } from 'components/application/ApplicationRoutes'

const accessTokenCookieName = 'admin_access_token'
const refreshTokenCookieName = 'admin_refresh_token'

export function cookieAttributes(): Cookies.CookieAttributes {
  return {
    domain: appConfig.COOKIE_DOMAIN,
    secure: appConfig.PROTOCOL === 'https',
    sameSite: 'None',
    expires: 155,
  } // 155 days, 5 months
}

type AuthStatus =
  | 'unauthenticated'
  | 'authenticating'
  | 'authenticated'
  | 'authentication_failed'

export default class SessionEngine implements BaseEngine {
  public engineStatus: EngineStatus = 'off'
  public authStatus: AuthStatus = 'unauthenticated'

  private _sessionTransportLayer = new SessionTransportLayer()
  private _accessToken: string | undefined
  private _refreshToken: string | undefined

  private _session: SessionModel | null = null

  constructor() {
    makeAutoObservable(this)
    autoBind(this)
    reaction(
      () => this._accessToken,
      () => {
        this._storeAccessToken(this._accessToken)
      },
    )
    reaction(
      () => this._refreshToken,
      () => {
        this._storeRefreshToken(this._refreshToken)
      },
    )
  }

  public get accessToken() {
    return this._accessToken
  }

  public getAuthorizationHeader(): string | undefined {
    if (this._accessToken) {
      return this._accessToken
    }
    return undefined
  }

  public getRefreshToken(): string | undefined {
    return this._refreshToken
  }

  public getAccessToken(): string | undefined {
    return this._accessToken
  }

  public setAuthorizationFromHeaders(
    headers: AxiosResponse<any, any>['headers'],
  ): void {
    if (headers['access-token'] && headers['refresh-token']) {
      this._accessToken = headers['access-token']
      this._refreshToken = headers['refresh-token']
    }
  }

  public async start(): Promise<EngineStatus> {
    if (this.engineStatus !== 'off') {
      return this.engineStatus
    }
    this._setEngineStatus('starting')

    if (!window.location.pathname.includes('logout')) {
      this._accessToken = this._getAccessTokenFromStore()
      this._refreshToken = this._getRefreshTokenFromStore()

      const response = await this._sessionTransportLayer.getSession()
      if (response.outcome === 'success') {
        this._buildSession(response.data.admin_user)
      }
      this._setAuthStatus(
        response.outcome !== 'success'
          ? 'authentication_failed'
          : 'authenticated',
      )
      this._setEngineStatus('running')
    } else {
      this._setEngineStatus('running')
    }
    return this.engineStatus
  }

  public get session() {
    return this._session
  }

  public userTakeover(userId: number) {
    return this._sessionTransportLayer.userTakeover(userId)
  }

  public async loginWithPassword(
    props: Admin.SessionsCreate.RequestBody['admin_user'],
  ) {
    this._setAuthStatus('authenticating')
    const response = await this._sessionTransportLayer.loginWithPassword(props)
    this._setAuthStatus(
      response.outcome !== 'success' ? 'unauthenticated' : 'authenticated',
    )
    if (response.outcome === 'success') {
      this._buildSession(response.data.admin_user)
    }
    return response
  }

  private _buildSession(
    session: Admin.SessionsCreate.ResponseBody['admin_user'],
  ) {
    if (this._session) {
      this._session.setAttributes(session)
    } else {
      this._session = new SessionModel(this._sessionTransportLayer, session)
    }
  }

  public async logout() {
    this.deleteAuthorization()
    window.location.href = ROUTES.LOGIN.$buildPath({})
  }

  public deleteAuthorization(): void {
    Cookies.remove(accessTokenCookieName, cookieAttributes())
    Cookies.remove(refreshTokenCookieName, cookieAttributes())
    this._accessToken = undefined
    this._refreshToken = undefined
  }

  private _setAuthStatus(status: AuthStatus) {
    this.authStatus = status
  }

  private _setEngineStatus(status: EngineStatus) {
    this.engineStatus = status
  }

  private _getAccessTokenFromStore() {
    return Cookies.get(accessTokenCookieName)
  }

  private _getRefreshTokenFromStore() {
    return Cookies.get(refreshTokenCookieName)
  }

  private _storeAccessToken(value: string | undefined) {
    if (value === undefined) {
      Cookies.remove(accessTokenCookieName, cookieAttributes())
    } else {
      Cookies.set(accessTokenCookieName, value, cookieAttributes())
    }
  }

  private _storeRefreshToken(value: string | undefined) {
    if (value === undefined) {
      Cookies.remove(refreshTokenCookieName, cookieAttributes())
    } else {
      Cookies.set(refreshTokenCookieName, value, cookieAttributes())
    }
  }
}
