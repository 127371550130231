import engine from 'engine'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

export default function ApplicationLogoutScene() {
  useEffect(() => {
    engine.sessionEngine.logout()
  }, [])

  return (
    <Helmet>
      <title>Logout | Scribe | Admin</title>
    </Helmet>
  )
}
