import axios, { AxiosInstance, isAxiosError } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { ROUTES } from 'components/application/ApplicationRoutes'
import SessionEngine from 'engine/session/SessionEngine'
import NavigationService from 'services/NavigationService'
import appConfig from 'utils/appConfig'

const defaultHeaders = {
  Accept: 'application/json',
  'X-Client-Type': 'AdminWepApplication',
  'X-Api-Version': 5,
}

export type SimpleRequestOutcome<SuccessPayload> =
  | {
      outcome: 'success'
      data: SuccessPayload
    }
  | {
      outcome: 'error'
    }

export type RequestOutcome<SuccessPayload, ErrorPayload> =
  | {
      outcome: 'success'
      data: SuccessPayload
    }
  | {
      outcome: 'error'
      data: ErrorPayload
      status: number
    }

class ApiService {
  private _axiosInstance: AxiosInstance | null = null
  private _sessionEngine: SessionEngine | null = null

  private get axiosInstance(): AxiosInstance {
    if (!this._axiosInstance) {
      throw new Error('ApiService is not initialized')
    }
    return this._axiosInstance
  }

  private get sessionEngine() {
    if (!this._sessionEngine) {
      throw new Error('ApiService is not initialized')
    }
    return this._sessionEngine
  }

  public init(sessionEngine: SessionEngine) {
    this._sessionEngine = sessionEngine

    this._axiosInstance = axios.create({
      headers: defaultHeaders,
      baseURL: appConfig.API_BASE_URL,
    })

    createAuthRefreshInterceptor(
      this.axiosInstance,
      this.refreshAuthLogic.bind(this),
    )
    this._createRequestInterceptor()
    this._createResponseInterceptor()
  }

  public get<SuccessPayload, ErrorPayload = void>(
    url: string,
  ): Promise<RequestOutcome<SuccessPayload, ErrorPayload>> {
    return this.axiosInstance
      .get(url)
      .then((response) => {
        return {
          outcome: <const>'success',
          data: response.data,
        }
      })
      .catch((error) => {
        this._handle500Error(error)
        return {
          outcome: <const>'error',
          data: error?.response?.data,
          status: error?.response?.status,
        }
      })
  }

  public delete<SuccessPayload = string, ErrorPayload = void>(
    url: string,
  ): Promise<RequestOutcome<SuccessPayload, ErrorPayload>> {
    return this.axiosInstance
      .delete(url)
      .then((response) => {
        return {
          outcome: <const>'success',
          data: response.data,
        }
      })
      .catch((error) => {
        this._handle500Error(error)
        return {
          outcome: <const>'error',
          data: error?.response?.data,
          status: error?.response?.status,
        }
      })
  }

  public post<Body, SuccessPayload, ErrorPayload = void>(
    url: string,
    body: Body | null = null,
    abortController?: AbortController,
  ): Promise<RequestOutcome<SuccessPayload, ErrorPayload>> {
    return this.axiosInstance
      .post(url, body, { signal: abortController?.signal })
      .then((response) => {
        return {
          outcome: <const>'success',
          data: response?.data,
        }
      })
      .catch((error) => {
        this._handle500Error(error)
        return {
          outcome: <const>'error',
          data: error?.response?.data,
          status: error?.response?.status,
        }
      })
  }

  public put<Body, SuccessPayload, ErrorPayload>(
    url: string,
    body: Body | null = null,
  ): Promise<RequestOutcome<SuccessPayload, ErrorPayload>> {
    return this.axiosInstance
      .put(url, body)
      .then((response) => {
        return {
          outcome: <const>'success',
          data: response.data,
        }
      })
      .catch((error) => {
        this._handle500Error(error)
        return {
          outcome: <const>'error',
          data: error?.response?.data,
          status: error?.response?.status,
        }
      })
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private refreshAuthLogic(failedRequest: any) {
    if (
      location.pathname === ROUTES.LOGOUT.$buildPath({}) ||
      !this.sessionEngine.getAuthorizationHeader() ||
      !this.sessionEngine.getRefreshToken()
    ) {
      return Promise.reject()
    }
    return (
      axios
        .post(
          `${appConfig.API_BASE_URL}sessions/tokens`,
          {},
          {
            headers: {
              ...defaultHeaders,
              Authorization: this.sessionEngine.getAuthorizationHeader(),
              'Refresh-Token': this.sessionEngine.getRefreshToken(),
            },
          },
        )
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        .then((tokenRefreshResponse: any) => {
          this.sessionEngine.setAuthorizationFromHeaders(
            tokenRefreshResponse.headers,
          )
          failedRequest.response.config.headers.set(
            'Authorization',
            this.sessionEngine.getAuthorizationHeader(),
            true,
          )

          return Promise.resolve()
        })
        .catch((error) => {
          if (window.location.pathname !== ROUTES.LOGIN.$buildPath({})) {
            NavigationService.navigate(ROUTES.LOGOUT.$buildPath({}))
          }
          return Promise.reject(error)
        })
    )
  }

  private _createRequestInterceptor() {
    this.axiosInstance.interceptors.request.use((config) => {
      if (!config.headers) {
        return config
      }

      if (this.sessionEngine.getAuthorizationHeader()) {
        config.headers.Authorization =
          this.sessionEngine.getAuthorizationHeader()
      }

      return config
    })
  }

  private _createResponseInterceptor() {
    this.axiosInstance.interceptors.response.use(
      (response) => {
        if (response.headers) {
          this.sessionEngine.setAuthorizationFromHeaders(response.headers)
        }
        return response
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private _handle500Error(error: any) {
    if (error?.response?.status === 500) {
      if (isAxiosError(error)) {
        console.log('ApiService axios error', error.response?.data)
      } else {
        console.log('ApiService other error', { error })
      }
    }
  }
}

export default new ApiService()
