import { Admin, EnterpriseCheckout } from '@scribe/types/src/admin'
import autoBind from 'auto-bind'
import { EngineStatus } from 'engine/BaseEngine'
import EnterpriseCheckoutModel from 'engine/enterpriseCheckouts/EnterpriseCheckoutModel'
import EnterpriseCheckoutsTransportLayer from 'engine/enterpriseCheckouts/EnterpriseCheckoutsTransportLayer'
import { updateMapWithList } from 'engine/mobxUtils'
import compact from 'lodash/compact'
import { makeAutoObservable } from 'mobx'

export default class EnterpriseCheckoutsEngine {
  public engineStatus: EngineStatus = 'off'

  private _enterpriseCheckoutsTransportLayer =
    new EnterpriseCheckoutsTransportLayer()

  private _enterpriseCheckouts = new Map<string, EnterpriseCheckoutModel>()

  constructor() {
    makeAutoObservable(this)
    autoBind(this)
  }

  public forUuid(uuid: string) {
    return this._enterpriseCheckouts.get(uuid)
  }

  public async start(): Promise<EngineStatus> {
    this.engineStatus = 'running'
    return this.engineStatus
  }

  public async stop(): Promise<EngineStatus> {
    this.engineStatus = 'off'
    return this.engineStatus
  }

  public async getEnterpriseCheckouts(
    params: Admin.EnterpriseCheckoutsIndex.RequestQuery,
  ) {
    const response =
      await this._enterpriseCheckoutsTransportLayer.getEnterpriseCheckouts(
        params,
      )
    if (response && response.outcome === 'success') {
      updateMapWithList(
        this._enterpriseCheckouts,
        response.data.enterprise_checkouts,
        this.buildModel.bind(this),
      )
      const enterpriseCheckouts = response.data.enterprise_checkouts.map(
        (enterpriseCheckout) => {
          return this._enterpriseCheckouts.get(enterpriseCheckout.uuid)
        },
      )
      return {
        enterpriseCheckouts: compact(enterpriseCheckouts),
        pagination: response.data.pagination,
      }
    }
    return null
  }

  public async getCoupons() {
    const response = await this._enterpriseCheckoutsTransportLayer.getCoupons()
    return response
  }

  public buildModel(enterpriseCheckout: Partial<EnterpriseCheckout>) {
    const model = new EnterpriseCheckoutModel(
      this._enterpriseCheckoutsTransportLayer,
      enterpriseCheckout,
    )
    this._enterpriseCheckouts.set(model.uuid, model)
    return model
  }

  public async deleteEnterpriseCheckout(id: number) {
    const response = await this._enterpriseCheckoutsTransportLayer.delete(id)
    return response
  }
}
