import RootEngine from './RootEngine'
import { configure, toJS } from 'mobx'

configure({ enforceActions: 'never' })

const engine = new RootEngine()

export default engine

declare global {
  interface Window {
    engine: RootEngine
    tojs: typeof toJS
  }
}

if (typeof window !== 'undefined') {
  window.engine = engine
  window.tojs = toJS
}
