import {
  Box,
  Group,
  LoadingOverlay,
  Pagination,
  Space,
  Table,
  TextInput,
} from '@mantine/core'
import { IconSearch, IconX } from '@tabler/icons-react'
import EnterpriseCheckoutsListItem from 'components/enterpriseCheckouts/EnterpriseCheckoutsListItem'
import EnterpriseCheckoutsListViewModel from 'components/enterpriseCheckouts/EnterpriseCheckoutsListViewModel'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

type EnterpriseCheckoutsListProps = {
  viewModel: EnterpriseCheckoutsListViewModel
}

export default observer(function EnterpriseCheckoutsList(
  props: EnterpriseCheckoutsListProps,
) {
  useEffect(() => {
    props.viewModel.loadPage(1)
  }, [props.viewModel])

  return (
    <>
      <Space h="sm" />
      <Group align="flex-end">
        <TextInput
          flex={1}
          variant="filled"
          size="sm"
          placeholder="Search for a company ..."
          leftSection={<IconSearch size={16} />}
          onChange={(event) =>
            props.viewModel.setSearchQuery(event.target.value)
          }
          value={props.viewModel.searchQuery}
          rightSection={
            props.viewModel.searchQuery ? (
              <IconX
                size={16}
                onClick={() => props.viewModel.setSearchQuery('')}
                style={{ cursor: 'pointer' }}
              />
            ) : null
          }
        />
      </Group>
      <Space h="sm" />
      <Box pos="relative">
        <Table striped highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Company Name</Table.Th>
              <Table.Th>First Name</Table.Th>
              <Table.Th>Last Name</Table.Th>
              <Table.Th>Email</Table.Th>
              <Table.Th>Seat Count</Table.Th>
              <Table.Th>Plan</Table.Th>
              <Table.Th>Contract</Table.Th>
              <Table.Th>Activation</Table.Th>
              <Table.Th w="230px" ta="center">
                Actions
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {props.viewModel.enterpriseCheckouts.map((enterpriseCheckout) => (
              <EnterpriseCheckoutsListItem
                key={`enterprise-checkout-${enterpriseCheckout.uuid}`}
                enterpriseCheckout={enterpriseCheckout}
                viewModel={props.viewModel}
              />
            ))}
          </Table.Tbody>
        </Table>
        <LoadingOverlay visible={props.viewModel.loading} />
      </Box>
      <Space h="sm" />
      {props.viewModel.pagination && (
        <Pagination
          total={props.viewModel.pagination.total_pages || 0}
          value={props.viewModel.pagination.current_page || 1}
          onChange={(page) => props.viewModel.loadPage(page)}
          mt="sm"
        />
      )}
      <Space h="xl" />
    </>
  )
})
