import { Button, Modal, Space } from '@mantine/core'
import CouponInput from 'components/ui/couponInput/CouponInput'
import WorkspaceModel from 'engine/workspaces/WorkspaceModel'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import NotificationService from 'services/NotificationService'

type WorkspacesListItemCouponModalProps = {
  opened: boolean
  onClose: () => void
  workspace: WorkspaceModel
}

export default observer(function WorkspacesListItemCouponModal(
  props: WorkspacesListItemCouponModalProps,
) {
  const [coupon, setCoupon] = useState<string>('')
  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      title={'Apply coupon'}
      centered
    >
      <CouponInput
        value={coupon}
        onChange={(value) => {
          if (value) {
            setCoupon(value)
          }
        }}
        error={null}
      />
      <Space h="md" />
      <Button
        onClick={async () => {
          if (coupon) {
            const response = await props.workspace.applyCoupon(coupon)
            if (response.outcome === 'success') {
              NotificationService.success('Coupon applied')
              props.onClose()
            }
          }
        }}
      >
        Extend
      </Button>
    </Modal>
  )
})
