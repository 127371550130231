import BaseEngine, { EngineStatus } from 'engine/BaseEngine'
import EnterpriseCheckoutsEngine from 'engine/enterpriseCheckouts/EnterpriseCheckoutsEngine'
import SessionEngine from 'engine/session/SessionEngine'
import SignatureTemplatesEngine from 'engine/signatureTemplates/SignatureTemplatesEngine'
import WorkspacesEngine from 'engine/workspaces/WorkspacesEngine'
import { makeAutoObservable } from 'mobx'
import ApiService from 'services/ApiService'
import NavigationService from 'services/NavigationService'

/**
 * 🛡 Each engine is independent of each other.
 * This guarantees a clean separation of concerns.
 */
export default class RootEngine implements BaseEngine {
  private _engineStatus: EngineStatus = 'off'

  public get engineStatus(): EngineStatus {
    return this._engineStatus
  }

  // Starting sub-engines
  public sessionEngine = new SessionEngine()
  public workspacesEngine = new WorkspacesEngine()
  public signatureTemplatesEngine = new SignatureTemplatesEngine()
  public enterpriseCheckoutsEngine = new EnterpriseCheckoutsEngine()

  constructor() {
    makeAutoObservable(this)
  }

  // 🔢 Control the starting sequence here
  public async start(): Promise<EngineStatus> {
    if (this.engineStatus !== 'off') {
      return this.engineStatus
    }
    this._setEngineStatus('starting')

    ApiService.init(this.sessionEngine)
    NavigationService.init()

    await this.sessionEngine.start()
    await this.workspacesEngine.start()
    await this.signatureTemplatesEngine.start()
    await this.enterpriseCheckoutsEngine.start()

    this._setEngineStatus('running')

    return this.engineStatus
  }

  private _setEngineStatus(status: EngineStatus) {
    if (this._engineStatus === status) {
      return
    }
    this._engineStatus = status
  }
}
