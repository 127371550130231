import { Divider, Title } from '@mantine/core'
import SignatureTemplatesList from 'components/signatureTemplates/SignatureTemplatesList'
import { Helmet } from 'react-helmet'

export default function SignatureTemplatesScene() {
  return (
    <>
      <Helmet>
        <title>Signature Templates | Scribe | Admin</title>
      </Helmet>
      <Title order={2}>Signatures Templates</Title>
      <Divider w="100%" size="sm" mt="sm" mb="sm" />
      <SignatureTemplatesList />
    </>
  )
}
