import { Button, Code, Group, useMantineColorScheme } from '@mantine/core'
import {
  IconBrightnessUp,
  IconBuildings,
  IconCashRegister,
  IconLogout,
  IconMoon,
  IconWritingSign,
} from '@tabler/icons-react'
import Logo from 'assets/logo.svg?react'
import LogoWhite from 'assets/logo-white.svg?react'
import { ROUTES } from 'components/application/ApplicationRoutes'
import { NavLink, Outlet } from 'react-router'
import NavigationService from 'services/NavigationService'
import classes from './ApplicationContentContainer.module.css'

export default function ApplicationContentContainer() {
  const { colorScheme, setColorScheme } = useMantineColorScheme()

  const data = [
    {
      label: 'Workspaces',
      icon: IconBuildings,
      route: ROUTES.WORKSPACES,
    },
    {
      label: 'Enterprise checkouts',
      icon: IconCashRegister,
      route: ROUTES.ENTERPRISE_CHECKOUT,
    },
    {
      label: 'Signature templates',
      icon: IconWritingSign,
      route: ROUTES.SIGNATURE_TEMPLATES,
    },
  ]

  const links = data.map((item) => (
    <NavLink
      to={item.route.$buildPath({})}
      className={({ isActive }) =>
        isActive ? `${classes.linkActive} ${classes.link}` : classes.link
      }
      key={item.label}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </NavLink>
  ))

  return (
    <div className={classes.container}>
      <nav className={classes.navbar}>
        <div className={classes.navbarMain}>
          <Group className={classes.header} justify="space-between">
            {colorScheme === 'dark' ? (
              <LogoWhite width="130" height="auto" />
            ) : (
              <Logo width="130" height="auto" />
            )}
            <Button
              px="10"
              variant="default"
              onClick={() => {
                setColorScheme(colorScheme === 'light' ? 'dark' : 'light')
              }}
            >
              {colorScheme === 'light' && <IconMoon size={20} />}
              {colorScheme === 'dark' && <IconBrightnessUp size={20} />}
            </Button>
          </Group>
          {links}
        </div>

        <div className={classes.footer}>
          <a
            href="#"
            className={classes.link}
            onClick={() =>
              NavigationService.navigate(ROUTES.LOGOUT.$buildPath({}))
            }
          >
            <IconLogout className={classes.linkIcon} stroke={1.5} />
            <span>Logout</span>
          </a>
        </div>
      </nav>
      <div className={classes.content}>
        <Outlet />
      </div>
    </div>
  )
}
