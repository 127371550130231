import { Admin } from '@scribe/types/src/admin'
import ApiService from 'services/ApiService'

export default class SessionTransportLayer {
  public async getSession() {
    return ApiService.get<Admin.SessionsIndex.ResponseBody>('/sessions')
  }

  public async loginWithPassword(
    body: Admin.SessionsCreate.RequestBody['admin_user'],
  ) {
    return ApiService.post<
      Admin.SessionsCreate.RequestBody,
      Admin.SessionsCreate.ResponseBody,
      void
    >('/sessions', {
      admin_user: body,
    })
  }

  public async userTakeover(userId: number) {
    return ApiService.post<void, void, void>(
      `/workspace_takeover?user_id=${userId}`,
    )
  }
}
