import { Button, PasswordInput, TextInput } from '@mantine/core'
import LoginFormViewModel from 'components/session/LoginFormViewModel'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

export default observer(function SessionLoginForm() {
  const viewModel = useMemo(() => new LoginFormViewModel(), [])
  return (
    <>
      <TextInput
        label="Email"
        value={viewModel.email}
        onChange={handleEmailChange}
        placeholder="you@scribe-mail.com"
        required
      />
      <PasswordInput
        value={viewModel.password}
        onChange={handlePasswordChange}
        label="Password"
        placeholder="Your password"
        required
        mt="md"
      />
      <Button fullWidth mt="xl" onClick={viewModel.signIn}>
        Sign in
      </Button>
    </>
  )

  function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    viewModel.setPassword(event.target.value)
  }

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    viewModel.setEmail(event.target.value)
  }
})
