import { Center, Container, Paper } from '@mantine/core'
import Logo from 'assets/logo.svg?react'
import SessionLoginForm from 'components/session/LoginForm'
import { Helmet } from 'react-helmet'

export default function ApplicationLoginScene() {
  return (
    <>
      <Helmet>
        <title>Login | Scribe | Admin</title>
      </Helmet>
      <Container size={420} my={40}>
        <Center>
          <Logo width="200" />
        </Center>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <SessionLoginForm />
        </Paper>
      </Container>
    </>
  )
}
