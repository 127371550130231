import { ROUTES } from 'components/application/ApplicationRoutes'
import engine from 'engine'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Outlet } from 'react-router'
import NavigationService from 'services/NavigationService'

export default observer(function ApplicationNotAuthenticatedContainer() {
  const authenticated = engine.sessionEngine.authStatus === 'authenticated'

  useEffect(() => {
    if (engine.sessionEngine.authStatus === 'authenticated') {
      NavigationService.navigate(ROUTES.ROOT.$path())
    }
  }, [])

  return <>{!authenticated && <Outlet />}</>
})
