import { Admin, Coupon, CouponList } from '@scribe/types/src/admin'
import autoBind from 'auto-bind'
import { makeAutoObservable } from 'mobx'
import ApiService from 'services/ApiService'

export default class CouponsFormViewModel {
  private _onSuccess: (coupond: Coupon) => void
  private _id: string = ''
  private _name: string = ''
  private _percentOff: string = '0'
  private _duration: Coupon['duration'] = 'once'
  private _loading: boolean = false

  constructor(onSuccess: (coupond: Coupon) => void) {
    makeAutoObservable(this)
    autoBind(this)
    this._onSuccess = onSuccess
  }

  public get loading() {
    return this._loading
  }

  public get name() {
    return this._name
  }

  public setName(name: string) {
    this._name = name
  }

  public get percentOff() {
    return this._percentOff
  }

  public setPercentOff(percentOff: string) {
    this._percentOff = percentOff
  }

  public get duration() {
    return this._duration
  }

  public setDuration(duration: Coupon['duration']) {
    this._duration = duration
  }

  public get id() {
    return this._id
  }

  public setId(id: string) {
    this._id = id
  }

  public isValid() {
    return (
      this._name.length > 0 &&
      this._percentOff !== '0' &&
      this._duration !== null
    )
  }

  public async create() {
    if (!this.isValid()) {
      return
    }
    this._loading = true
    const response = await ApiService.post<
      Admin.CouponsCreate.RequestBody,
      Admin.CouponsCreate.ResponseBody
    >('/coupons', {
      coupon: {
        id: this._id,
        name: this._name,
        percent_off: this._percentOff,
        duration: this._duration,
      },
    })
    if (response.outcome === 'success') {
      this._onSuccess(response.data.coupon)
      this._name = ''
      this._percentOff = '0'
      this._duration = 'once'
    }
    this._loading = false
  }
}
