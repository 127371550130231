import { NavigateFunction } from 'react-router-dom'
import appConfig from 'utils/appConfig'

class NavigationService {
  private _isInitialized = false
  private _navigate: NavigateFunction | null = null

  public init() {
    if (this._isInitialized) {
      return
    }
    this._isInitialized = true
  }

  public setNavigateFunction(navigate: NavigateFunction) {
    this._navigate = navigate
  }

  public navigate(pathname: string) {
    if (this._navigate) {
      this._navigate(
        pathname.replace(`${appConfig.PROTOCOL}://${appConfig.APP_HOST}/`, ''),
      )
    } else {
      window.location.pathname = pathname.replace(
        `${appConfig.PROTOCOL}://${appConfig.APP_HOST}/`,
        '',
      )
    }
  }

  public replace(pathname: string) {
    if (this._navigate) {
      this._navigate(pathname, { replace: true })
    }
  }

  public getCurrentPath() {
    return window.location.pathname
  }
}

export default new NavigationService()
