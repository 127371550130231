import autoBind from 'auto-bind'
import { ROUTES } from 'components/application/ApplicationRoutes'
import engine from 'engine'
import { makeAutoObservable } from 'mobx'
import NavigationService from 'services/NavigationService'

export default class LoginFormViewModel {
  private _password = ''
  private _email = ''

  constructor() {
    makeAutoObservable(this)
    autoBind(this)
  }

  public get password() {
    return this._password
  }

  public setPassword(password: string) {
    this._password = password
  }

  public get email() {
    return this._email
  }

  public setEmail(email: string) {
    this._email = email
  }

  public async signIn() {
    if (!this.email || !this.password) {
      return
    }

    const response = await engine.sessionEngine.loginWithPassword({
      email: this.email,
      password: this.password,
    })

    if (response.outcome === 'success') {
      NavigationService.navigate(ROUTES.ROOT.$buildPath({}))
    }
  }
}
