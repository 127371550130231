import { Admin, SignatureTemplate } from '@scribe/types/src/admin'
import autoBind from 'auto-bind'
import { EngineStatus } from 'engine/BaseEngine'
import { updateMapWithList } from 'engine/mobxUtils'
import SignatureTemplateModel from 'engine/signatureTemplates/SignatureTemplateModel'
import SignatureTemplatesTransportLayer from 'engine/signatureTemplates/SignatureTemplatesTransportLayer'
import compact from 'lodash/compact'
import { makeAutoObservable } from 'mobx'

export default class SignatureTemplatesEngine {
  public engineStatus: EngineStatus = 'off'

  private _signatureTemplatesTransportLayer =
    new SignatureTemplatesTransportLayer()

  private _signatureTemplates = new Map<string, SignatureTemplateModel>()

  constructor() {
    makeAutoObservable(this)
    autoBind(this)
  }

  public async start(): Promise<EngineStatus> {
    this.engineStatus = 'running'
    return this.engineStatus
  }

  public async stop(): Promise<EngineStatus> {
    this.engineStatus = 'off'
    return this.engineStatus
  }

  public async getSignatureTemplates(
    params: Admin.SignatureTemplatesIndex.RequestQuery,
  ) {
    const response =
      await this._signatureTemplatesTransportLayer.getSignatureTemplates(params)
    if (response) {
      updateMapWithList(
        this._signatureTemplates,
        response.signature_templates,
        this._buildModel.bind(this),
      )
      const signatureTemplates = response.signature_templates.map(
        (signatureTemplate) => {
          return this._signatureTemplates.get(signatureTemplate.uuid)
        },
      )
      return {
        signatureTemplates: compact(signatureTemplates),
        pagination: response.pagination,
      }
    }
    return null
  }

  private _buildModel(signatureTemplate: Partial<SignatureTemplate>) {
    return new SignatureTemplateModel(
      this._signatureTemplatesTransportLayer,
      signatureTemplate,
    )
  }
}
