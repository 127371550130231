import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import ApplicationRoutes from 'components/application/ApplicationRoutes'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import engine from 'engine'
import countries from 'i18n-iso-countries'
import enCountries from 'i18n-iso-countries/langs/en.json'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import appConfig from 'utils/appConfig'

import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/notifications/styles.css'

dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('Europe/Paris')

countries.registerLocale(enCountries)

Bugsnag.start({
  apiKey: appConfig.BUGSNAG_API_KEY,
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: appConfig.ENV,
  plugins: [new BugsnagPluginReact()],
})

function ErrorBoundary(props: { children: React.ReactNode }) {
  const plugin = Bugsnag.getPlugin('react')

  if (plugin) {
    const ErrorBoundaryWrapper = plugin.createErrorBoundary(React)
    return <ErrorBoundaryWrapper>{props.children}</ErrorBoundaryWrapper>
  } else {
    return <>{props.children}</>
  }
}

const App = observer(function App() {
  const rootEngineStatus = engine.engineStatus

  useEffect(() => {
    engine.start()
  }, [])

  return (
    <ErrorBoundary>
      {rootEngineStatus === 'running' && (
        <BrowserRouter>
          <ApplicationRoutes />
        </BrowserRouter>
      )}
    </ErrorBoundary>
  )
})

export default App
