import { Modal } from '@mantine/core'
import CouponsForm from 'components/coupons/CouponsForm'
import CouponsFormViewModel from 'components/coupons/CouponsFormViewModel'
import { observer } from 'mobx-react-lite'

type CouponsFormModalProps = {
  formViewModel: CouponsFormViewModel
  opened: boolean
  onClose: () => void
}

export default observer(function CouponsFormModal(
  props: CouponsFormModalProps,
) {
  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      title="Create coupon"
      centered
    >
      <CouponsForm viewModel={props.formViewModel} />
    </Modal>
  )
})
