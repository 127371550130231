const appConfig = {
  PROTOCOL: getProcessEnv('VITE_PROTOCOL'),
  APP_HOST: getProcessEnv('VITE_APP_HOST'),
  API_BASE_URL: getProcessEnv('VITE_API_BASE_URL'),
  COOKIE_DOMAIN: getProcessEnv('VITE_COOKIE_DOMAIN'),
  ENV: getProcessEnv('VITE_ENV'),
  BUGSNAG_API_KEY: getProcessEnv('VITE_BUGSNAG_API_KEY'),
  DOCUMENTS_BASE_URL: getProcessEnv('VITE_DOCUMENTS_BASE_URL'),
}

export default appConfig

function getProcessEnv(key: string): string {
  const value = import.meta.env[key]
  if (!value) {
    throw new Error(`Missing environment variable: ${key}`)
  }
  return value
}
