import { Coupon, EnterpriseCheckout } from '@scribe/types/src/admin'
import autoBind from 'auto-bind'
import engine from 'engine'
import { makeAutoObservable } from 'mobx'
import NotificationService from 'services/NotificationService'

export default class EnterpriseCheckoutsFormViewModel {
  private _enterpriseCheckoutUuid: string
  private _loading: boolean = false
  private _couponsLoading: boolean = false
  private _coupons: Coupon[] = []

  private _firstName: string = ''
  private _lastName: string = ''
  private _jobPosition: string = ''
  private _email: string = ''
  private _seatCount: number = 100
  private _plan: EnterpriseCheckout['plan'] = 'enterprise'
  private _billingInterval: EnterpriseCheckout['billing_interval'] = 'yearly'
  private _stripeCouponId: string = ''
  private _companyName: string = ''
  private _streetName: string = ''
  private _streetNumber: string = ''
  private _boxNumber: string = ''
  private _city: string = ''
  private _zipCode: string = ''
  private _countryCode: string = ''
  private _emails: string[] = []
  private _currencyCode: EnterpriseCheckout['currency_code'] = 'USD'

  private _firstNameError: string | null = null
  private _lastNameError: string | null = null
  private _jobPositionError: string | null = null
  private _emailError: string | null = null
  private _seatCountError: string | null = null
  private _planError: string | null = null
  private _billingIntervalError: string | null = null
  private _stripeCouponIdError: string | null = null
  private _companyNameError: string | null = null
  private _streetNameError: string | null = null
  private _streetNumberError: string | null = null
  private _boxNumberError: string | null = null
  private _cityError: string | null = null
  private _zipCodeError: string | null = null
  private _countryCodeError: string | null = null
  private _emailsError: string | null = null
  private _currencyCodeError: string | null = null

  constructor(enterpriseCheckoutUuid: string) {
    makeAutoObservable(this)
    autoBind(this)
    this._enterpriseCheckoutUuid = enterpriseCheckoutUuid
    this._firstName = this.enterpriseCheckout?.firstName || ''
    this._lastName = this.enterpriseCheckout?.lastName || ''
    this._jobPosition = this.enterpriseCheckout?.jobPosition || ''
    this._email = this.enterpriseCheckout?.email || ''
    this._seatCount = this.enterpriseCheckout?.seatCount || 100
    this._plan = this.enterpriseCheckout?.plan || 'enterprise'
    this._billingInterval = this.enterpriseCheckout?.billingInterval || 'yearly'
    this._companyName = this.enterpriseCheckout?.companyName || ''
    this._streetName = this.enterpriseCheckout?.streetName || ''
    this._streetNumber = this.enterpriseCheckout?.streetNumber || ''
    this._boxNumber = this.enterpriseCheckout?.boxNumber || ''
    this._city = this.enterpriseCheckout?.city || ''
    this._zipCode = this.enterpriseCheckout?.zipCode || ''
    this._countryCode = this.enterpriseCheckout?.countryCode || ''
    this._emails = this.enterpriseCheckout?.emails || []
    this._stripeCouponId = this.enterpriseCheckout?.stripeCouponId || ''
    this._currencyCode = this.enterpriseCheckout?.currencyCode || 'USD'
  }

  public get enterpriseCheckout() {
    return engine.enterpriseCheckoutsEngine.forUuid(
      this._enterpriseCheckoutUuid,
    )
  }

  public async getCoupons() {
    this._couponsLoading = true
    const response = await engine.enterpriseCheckoutsEngine.getCoupons()
    if (response && response.outcome === 'success') {
      this._coupons = response.data.coupons
    }
    this._couponsLoading = false
  }

  public get couponsLoading() {
    return this._couponsLoading
  }

  public get loading() {
    return this._loading
  }

  public get firstName() {
    return this._firstName
  }

  public setFirstName(firstName: string) {
    this._firstName = firstName
  }

  public get lastName() {
    return this._lastName
  }

  public setLastName(lastName: string) {
    this._lastName = lastName
  }

  public get jobPosition() {
    return this._jobPosition
  }

  public setJobPosition(jobPosition: string) {
    this._jobPosition = jobPosition
  }

  public get email() {
    return this._email
  }

  public setEmail(email: string) {
    this._email = email
  }

  public get seatCount() {
    return this._seatCount
  }

  public setSeatCount(seatCount: number) {
    this._seatCount = seatCount
  }

  public get plan() {
    return this._plan
  }

  public setPlan(plan: EnterpriseCheckout['plan']) {
    this._plan = plan
  }

  public get billingInterval() {
    return this._billingInterval
  }

  public setBillingInterval(
    billingInterval: EnterpriseCheckout['billing_interval'],
  ) {
    this._billingInterval = billingInterval
  }

  public get stripeCouponId() {
    return this._stripeCouponId
  }

  public setStripeCouponId(stripeCouponId: string | null) {
    this._stripeCouponId = stripeCouponId || ''
  }

  public get companyName() {
    return this._companyName
  }

  public setCompanyName(companyName: string) {
    this._companyName = companyName
  }

  public get streetName() {
    return this._streetName
  }

  public setStreetName(streetName: string) {
    this._streetName = streetName
  }

  public get streetNumber() {
    return this._streetNumber
  }

  public setStreetNumber(streetNumber: string) {
    this._streetNumber = streetNumber
  }

  public get boxNumber() {
    return this._boxNumber
  }

  public setBoxNumber(boxNumber: string) {
    this._boxNumber = boxNumber
  }

  public get city() {
    return this._city
  }

  public setCity(city: string) {
    this._city = city
  }

  public get zipCode() {
    return this._zipCode
  }

  public setZipCode(zipCode: string) {
    this._zipCode = zipCode
  }

  public get countryCode() {
    return this._countryCode
  }

  public setCountryCode(countryCode: string | null) {
    this._countryCode = countryCode || ''
  }

  public get emails() {
    return this._emails
  }

  public setEmails(emails: string[]) {
    this._emails = emails
  }

  public get companyNameError() {
    return this._companyNameError
  }

  public get streetNameError() {
    return this._streetNameError
  }

  public get streetNumberError() {
    return this._streetNumberError
  }

  public get boxNumberError() {
    return this._boxNumberError
  }

  public get cityError() {
    return this._cityError
  }

  public get zipCodeError() {
    return this._zipCodeError
  }

  public get countryCodeError() {
    return this._countryCodeError
  }

  public get emailsError() {
    return this._emailsError
  }

  public get firstNameError() {
    return this._firstNameError
  }

  public get lastNameError() {
    return this._lastNameError
  }

  public get jobPositionError() {
    return this._jobPositionError
  }

  public get emailError() {
    return this._emailError
  }

  public get seatCountError() {
    return this._seatCountError
  }

  public get planError() {
    return this._planError
  }

  public get billingIntervalError() {
    return this._billingIntervalError
  }

  public get stripeCouponIdError() {
    return this._stripeCouponIdError
  }

  public get coupons() {
    return this._coupons
  }

  public get currencyCode() {
    return this._currencyCode
  }

  public setCurrencyCode(currencyCode: EnterpriseCheckout['currency_code']) {
    this._currencyCode = currencyCode
  }

  public get currencyCodeError() {
    return this._currencyCodeError
  }

  public async save(onSuccess?: () => void) {
    console.log(this.enterpriseCheckout)
    if (!this.enterpriseCheckout) {
      return
    }
    this._loading = true
    this._firstNameError = null
    this._lastNameError = null
    this._jobPositionError = null
    this._emailError = null
    this._seatCountError = null
    this._planError = null
    this._billingIntervalError = null
    this._stripeCouponIdError = null
    this._companyNameError = null
    this._streetNameError = null
    this._streetNumberError = null
    this._boxNumberError = null
    this._cityError = null
    this._zipCodeError = null
    this._countryCodeError = null
    this._currencyCodeError = null

    const attributes = {
      uuid: this.enterpriseCheckout.uuid,
      first_name: this.firstName,
      last_name: this.lastName,
      job_position: this.jobPosition,
      email: this.email,
      seat_count: this.seatCount,
      plan: this.plan,
      billing_interval: this.billingInterval,
      stripe_coupon_id: this.stripeCouponId,
      company_name: this.companyName,
      street_name: this.streetName,
      street_number: this.streetNumber,
      box_number: this.boxNumber,
      city: this.city,
      zip_code: this.zipCode,
      country_code: this.countryCode,
      emails: this.emails,
      currency_code: this.currencyCode || 'USD',
    }
    const response = await this.enterpriseCheckout.save(attributes)
    if (response && response.outcome === 'success') {
      NotificationService.success('Enterprise checkout saved')
      if (onSuccess) {
        onSuccess()
      }
    } else if (response) {
      NotificationService.error(
        'An error occurred while saving the enterprise checkout',
      )
      this._firstNameError = response.data.errors?.first_name?.message || null
      this._lastNameError = response.data.errors?.last_name?.message || null
      this._emailError = response.data.errors?.email?.message || null
      this._seatCountError = response.data.errors?.seat_count?.message || null
      this._planError = response.data.errors?.plan?.message || null
      this._billingIntervalError =
        response.data.errors?.billing_interval?.message || null
      this._companyNameError =
        response.data.errors?.company_name?.message || null
      this._currencyCodeError =
        response.data.errors?.currency_code?.message || null
    }
    this._loading = false
  }
}
