import { notifications } from '@mantine/notifications'

export type NotificationType = 'success' | 'error' | 'info'

class NotificationService {
  private isInitialized = true

  public init() {}

  public error(message: string, title?: string) {
    return notifications.show({
      title: title,
      message: message,
      withBorder: true,
      color: 'red',
    })
  }

  public success(message: string, title?: string) {
    return notifications.show({
      title: title,
      message: message,
      withBorder: true,
      color: 'teal',
    })
  }

  public info(message: string, title?: string) {
    return notifications.show({
      title: title,
      message: message,
      withBorder: true,
      color: 'blue',
    })
  }
}

export default new NotificationService()
