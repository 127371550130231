import { Subscription } from '@scribe/types/src/admin'
import autoBind from 'auto-bind'
import dayjs, { Dayjs } from 'dayjs'
import engine from 'engine'
import BaseModel from 'engine/BaseModel'
import { mergeValuesToModel } from 'engine/mobxUtils'
import SubscriptionsTransportLayer from 'engine/workspaces/SubscriptionsTransportLayer'
import { makeAutoObservable, observable } from 'mobx'
import { createViewModel, IViewModel } from 'mobx-utils'

function defaultAttributes(): Subscription {
  return {
    id: null,
    workspace_uuid: '',
    uuid: '',
    status: 'active',
    kind: 'free',
    ends_at: null,
    starts_at: '',
    billing_interval: 'monthly',
    amount_cents: 0,
    app_sumo_codes_count: 0,
    created_at: '',
    updated_at: '',
  }
}

export default class WorkspaceModel implements BaseModel {
  private _subscription: Subscription & IViewModel<Subscription>
  private _subscriptionsTransportLayer: SubscriptionsTransportLayer
  private _persisted = false
  private _isSaving = false

  constructor(
    subscriptionsTransportLayer: SubscriptionsTransportLayer,
    attributes?: Partial<Subscription>,
  ) {
    makeAutoObservable(this)
    autoBind(this)
    this._subscription = createViewModel(
      observable({ ...defaultAttributes(), ...attributes }),
    )
    this._subscriptionsTransportLayer = subscriptionsTransportLayer
  }

  public get workspaceUuid() {
    return this._subscription.workspace_uuid
  }

  public get persisted() {
    return this._persisted
  }

  public get isSaving() {
    return this._isSaving
  }

  public get status() {
    return this._subscription.status
  }

  public get kind() {
    return this._subscription.kind
  }

  public get billingInterval() {
    return this._subscription.billing_interval
  }

  public get amountCents() {
    return this._subscription.amount_cents
  }

  public get billingIntervalUnit() {
    return this.billingInterval === 'monthly' ? 'month' : 'year'
  }

  public get kindText() {
    switch (this._subscription.kind) {
      case 'free':
        return 'Free'
      case 'app_sumo':
        return 'App Sumo'
      case 'free_trial':
        return 'Free trial'
      case 'paid':
        return 'Paid'
    }
  }

  public get endsAt() {
    if (!this._subscription.ends_at) {
      return null
    }
    return dayjs(this._subscription.ends_at)
  }

  public get startsAt() {
    if (!this._subscription.starts_at) {
      return null
    }
    return dayjs(this._subscription.starts_at)
  }

  public get id() {
    if (!this._subscription.id) {
      throw new Error('Subscription id is null')
    }
    return this._subscription.id
  }

  public get uuid() {
    if (this._subscription.uuid === '') {
      throw new Error('Subscription uuid is empty')
    }
    return this._subscription.uuid
  }

  public async extendFreeTrial(endsAt: Dayjs) {
    const response = await this._subscriptionsTransportLayer.extendFreeTrial(
      this.id.toString(),
      endsAt.toISOString(),
    )
    if (response.outcome === 'success') {
      engine.workspacesEngine.buildNewSubscriptionModel(
        response.data.subscription,
      )
    }
  }

  public get createdAt() {
    if (!this._subscription.created_at) {
      return null
    }
    return dayjs(this._subscription.created_at)
  }

  public get updatedAt() {
    if (!this._subscription.updated_at) {
      return null
    }
    return dayjs(this._subscription.updated_at)
  }

  public setAttributes(subscription: Subscription) {
    if (
      !this.updatedAt ||
      this.updatedAt.isSameOrBefore(dayjs(subscription.updated_at))
    ) {
      mergeValuesToModel(this._subscription.model, subscription)
    }
  }
}
