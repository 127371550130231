import { Button, Divider, Drawer, Group, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconPlus } from '@tabler/icons-react'
import EnterpriseCheckoutsForm from 'components/enterpriseCheckouts/EnterpriseCheckoutsForm'
import EnterpriseCheckoutsFormViewModel from 'components/enterpriseCheckouts/EnterpriseCheckoutsFormViewModel'
import EnterpriseCheckoutsList from 'components/enterpriseCheckouts/EnterpriseCheckoutsList'
import EnterpriseCheckoutsListViewModel from 'components/enterpriseCheckouts/EnterpriseCheckoutsListViewModel'
import engine from 'engine'
import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'

export default function EnterpriseCheckoutScene() {
  const [opened, { open, close }] = useDisclosure(false)

  const listViewModel = useMemo(
    () => new EnterpriseCheckoutsListViewModel(),
    [],
  )

  const viewModel = useMemo(
    () =>
      new EnterpriseCheckoutsFormViewModel(
        engine.enterpriseCheckoutsEngine.buildModel({}).uuid,
      ),
    [],
  )

  useEffect(() => {
    if (opened) {
      viewModel.getCoupons()
    }
  }, [opened, viewModel])

  return (
    <>
      <Helmet>
        <title>Enterprise checkouts | Scribe | Admin</title>
      </Helmet>
      <Group justify="space-between">
        <Title order={2}>Enterprise checkouts</Title>
        <Button leftSection={<IconPlus size={16} />} onClick={open}>
          New enterprise checkout
        </Button>
      </Group>
      <Divider w="100%" size="sm" mt="sm" mb="sm" />
      <EnterpriseCheckoutsList viewModel={listViewModel} />
      <Drawer
        opened={opened}
        onClose={close}
        position="right"
        size="xl"
        title="New enterprise checkout"
      >
        <EnterpriseCheckoutsForm
          viewModel={viewModel}
          onSuccess={() => {
            close()
            listViewModel.loadPage(1)
          }}
        />
      </Drawer>
    </>
  )
}
