import { Admin } from '@scribe/types/src/admin'
import autoBind from 'auto-bind'
import { mergeValuesToModel } from 'engine/mobxUtils'
import SessionTransportLayer from 'engine/session/SessionTransportLayer'
import { makeAutoObservable, observable } from 'mobx'
import { createViewModel, IViewModel } from 'mobx-utils'

type Session = Admin.SessionsIndex.ResponseBody['admin_user']

function defaultAttributes(): Session {
  return {
    id: null,
    email: '',
    first_name: 'null',
    last_name: 'null',
    workspace_takeover_ids: [],
  }
}

export default class SessionModel {
  private _session: Session & IViewModel<Session>
  private _sessionTransportLayer: SessionTransportLayer

  private _isSaving = false

  constructor(
    sessionTransportLayer: SessionTransportLayer,
    attributes?: Partial<Session>,
  ) {
    makeAutoObservable(this)
    autoBind(this)
    this._session = createViewModel(
      observable({ ...defaultAttributes(), ...attributes }),
    )
    this._sessionTransportLayer = sessionTransportLayer
  }

  public get isSaving(): boolean {
    return this._isSaving
  }

  public get id() {
    return this._session.id
  }

  public get email() {
    return this._session.email
  }

  public get firstName() {
    return this._session.first_name
  }

  public get lastName() {
    return this._session.last_name
  }

  public setAttributes(session: Partial<Session>) {
    mergeValuesToModel(this._session.model, session)
  }
}
