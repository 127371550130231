import autoBind from 'auto-bind'
import dayjs from 'dayjs'
import BaseModel from 'engine/BaseModel'
import WorkspacesTransportLayer from 'engine/workspaces/WorkspacesTransportLayer'
import { makeAutoObservable, observable } from 'mobx'
import { createViewModel, IViewModel } from 'mobx-utils'
import { mergeValuesToModel } from 'engine/mobxUtils'
import { Workspace } from '@scribe/types/src/admin'
import engine from 'engine'

export type LightWorkspace = Omit<Workspace, 'subscriptions'>

export const setupStepsCount = 3

function defaultAttributes(): LightWorkspace {
  return {
    id: null,
    uuid: '',
    name: '',
    teammates_count: 0,
    installed_teammates_count: 0,
    detected_email_provider: 'unknown',
    data_source_integrations: [],
    signature_installation_destination_integrations: [],
    first_signature_installed_at: null,
    currency_code: 'EUR',
    owner_user_email: '',
    stripe_coupon_id: null,
    owner_user_id: -1,
    updated_at: '',
    created_at: '',
  }
}

export default class WorkspaceModel implements BaseModel {
  private _workspace: LightWorkspace & IViewModel<LightWorkspace>
  private _workspacesTransportLayer: WorkspacesTransportLayer
  private _persisted = false
  private _isSaving = false
  private _deleted = false

  constructor(
    workspacesTransportLayer: WorkspacesTransportLayer,
    attributes?: Partial<LightWorkspace>,
  ) {
    makeAutoObservable(this)
    autoBind(this)
    this._workspace = createViewModel(
      observable({ ...defaultAttributes(), ...attributes }),
    )
    this._workspacesTransportLayer = workspacesTransportLayer
  }

  public get deleted() {
    return this._deleted
  }

  public get setupStepsCount() {
    let count = 0
    if (
      this._workspace.data_source_integrations.find(
        (integration) => integration.active,
      )
    ) {
      count++
    }
    if (
      this._workspace.signature_installation_destination_integrations.find(
        (integration) => integration.active,
      )
    ) {
      count++
    }
    if (this._workspace.first_signature_installed_at) {
      count++
    }
    return count
  }

  public get dataSourceIntegrations() {
    return this._workspace.data_source_integrations
  }

  public get signatureInstallationDestinationIntegrations() {
    return this._workspace.signature_installation_destination_integrations
  }

  public get firstSignatureInstalledAt() {
    if (!this._workspace.first_signature_installed_at) {
      return null
    }
    return dayjs(this._workspace.first_signature_installed_at)
  }

  public get persisted() {
    return this._persisted
  }

  public get isSaving() {
    return this._isSaving
  }

  public get id() {
    if (!this._workspace.id) {
      throw new Error('Workspace id is null')
    }
    return this._workspace.id
  }

  public get uuid() {
    if (this._workspace.uuid === '') {
      throw new Error('Workspace uuid is empty')
    }
    return this._workspace.uuid
  }

  public get name() {
    return this._workspace.name
  }

  public get ownerUserId() {
    return this._workspace.owner_user_id
  }

  public get ownerUserEmail() {
    return this._workspace.owner_user_email
  }

  public get teammatesCount() {
    return this._workspace.teammates_count
  }

  public get installedTeammatesCount() {
    return this._workspace.installed_teammates_count
  }

  public get subscriptions() {
    return engine.workspacesEngine.subscriptionsForWorkspace(this)
  }

  public get currentSubscription() {
    return this.subscriptions
      .sort((a, b) => b.endsAt?.diff(a.endsAt) ?? 0)
      .find(
        (subscription) =>
          (subscription.status === 'active' ||
            subscription.status === 'past_due') &&
          subscription.kind !== 'app_sumo',
      )
  }

  public async applyCoupon(couponId: string) {
    this._workspace.stripe_coupon_id = couponId
    if (!this._workspace.id) {
      throw new Error('Workspace id is null')
    }
    this._isSaving = true
    const response = await this._workspacesTransportLayer.updateWorkspace(
      this._workspace.id,
      {
        stripe_coupon_id: couponId,
      },
    )
    return response
  }

  public get appSumoSubscription() {
    return this.subscriptions.find(
      (subscription) => subscription.kind === 'app_sumo',
    )
  }

  public markAsDeleted() {
    this._deleted = true
  }

  public get currencyCode() {
    return this._workspace.currency_code
  }

  public get stripeCouponId() {
    return this._workspace.stripe_coupon_id
  }

  public get createdAt() {
    if (!this._workspace.created_at) {
      return null
    }
    return dayjs(this._workspace.created_at)
  }

  public get updatedAt() {
    if (!this._workspace.updated_at) {
      return null
    }
    return dayjs(this._workspace.updated_at)
  }

  public setAttributes(workspace: Workspace) {
    if (
      !this.updatedAt ||
      this.updatedAt.isSameOrBefore(dayjs(workspace.updated_at))
    ) {
      mergeValuesToModel(this._workspace.model, workspace)
    }
  }
}
