import { ROUTES } from 'components/application/ApplicationRoutes'
import engine from 'engine'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Outlet } from 'react-router'
import NavigationService from 'services/NavigationService'

export default observer(function ApplicationAuthenticatedContainer() {
  const authenticated =
    engine.sessionEngine.authStatus === 'authenticated' &&
    engine.sessionEngine.session !== null

  useEffect(() => {
    if (
      engine.sessionEngine.authStatus === 'unauthenticated' ||
      engine.sessionEngine.authStatus === 'authentication_failed'
    ) {
      NavigationService.navigate(ROUTES.LOGIN.$path())
    }
  }, [])

  return <>{authenticated && <Outlet />}</>
})
